import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const SocialLinks = ({ control }) => {
  return (
    <>
      <h4 className="margin-top--lg">Social</h4>
      <hr />
      <div className="form-row margin-top--sm margin-bottom--sm">
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.mobileNo"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Mobile no"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.facebook"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Facebook"
                type="text"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.instagram"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Instagram"
                type="text"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.linkedIn"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="LinkedIn"
                type="text"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.twitter"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Twitter"
                type="text"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
        <div className="form-row margin-top margin-bottom">
          <Controller
            name="social.youtube"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Youtube"
                type="text"
                variant="outlined"
                size="small"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <YouTubeIcon />
                    </InputAdornment>
                  )
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default SocialLinks;
