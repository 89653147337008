import { SwipeableDrawer } from '@mui/material';
import { getDate, getTime, getTimeSlot } from './inbox.service.js';
import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EventIcon from '@mui/icons-material/Event';

const MessagePopup = ({ popupClose, contact, isOpen, toggleDrawer }) => {
  return (
    <div>
      {window.innerWidth > 750 && (
        <div className="popup">
          <div className="popup-content">
            <div className="popup-header">
              <span className="close" onClick={popupClose}>
                &times;
              </span>
              <h2>
                {contact.source == 'contact_me'
                  ? 'Contact me'
                  : contact.source == 'stay_in_touch'
                  ? 'Footer'
                  : contact.source == 'meeting'
                  ? 'Request meeting'
                  : null}
              </h2>
              <p className="dateTime text--sm"> {getTime(contact.creation_time)}</p>
            </div>
            <div className="popup-details flex-container  align-center margin-top">
              <div>
                <p className="text--sm">Email</p>

                <p className="semi-bold">{contact.email}</p>
              </div>
              <div>
                <p className="text--sm"> Name</p>

                <p className="semi-bold">{contact.name}</p>
              </div>
              <div>
                <p className="text--sm">Mobile no.</p>

                <p className="semi-bold">{contact.mobile}</p>
              </div>
            </div>
            {contact.source === 'meeting' ? (
              <div className=" margin-top">
                <div className="flex-container">
                  <EventIcon color="primary" fontSize="small" />
                  <p className="text--sm">&nbsp; Meeting</p>
                </div>
                <p className="text--sm semi-bold">
                  {contact.meeting_title} - {getDate(contact.slot_start)} from
                  {getTimeSlot(contact.slot_start)} to {getTimeSlot(contact.slot_end)}
                </p>
              </div>
            ) : null}
            <div className="popup-message margin-top">
              <div className="flex-container">
                <EmailOutlinedIcon color="primary" fontSize="small" />
                <p className="text--sm">&nbsp; Message</p>
              </div>

              <p className="semi-bold">{contact.message}</p>
            </div>
          </div>
        </div>
      )}

      {window.innerWidth < 750 && (
        <SwipeableDrawer
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          anchor="bottom"
          PaperProps={{
            sx: {
              top: '40%',
              borderRadius: '15px',
              padding: '15px'
            }
          }}>
          <div>
            <h2>
              {contact.source == 'contact_me'
                ? 'Contact me'
                : contact.source == 'stay_in_touch'
                ? 'Footer'
                : contact.source == 'meeting'
                ? 'Request meeting'
                : null}
            </h2>
            <p className="dateTime text--sm"> {getTime(contact.creation_time)}</p>
          </div>
          <div className="popup-details flex-container direction-column  align-center margin-top">
            <div className="margin-bottom--sm">
              <p className="text--sm">Email</p>

              <p className="semi-bold">{contact.email}</p>
            </div>
            <div className="margin-bottom--sm">
              <p className="text--sm"> Name</p>

              <p className="semi-bold">{contact.name}</p>
            </div>
            <div className="margin-bottom--sm">
              <p className="text--sm">Mobile no.</p>

              <p className="semi-bold">{contact.mobile}</p>
            </div>
            {contact.source === 'meeting' ? (
              <div className=" margin-top">
                <div className="flex-container">
                  <EventIcon color="primary" fontSize="small" />
                  <p className="text--sm">&nbsp; Meeting</p>
                </div>
                <p className="text--sm semi-bold">
                  {contact.meeting_title} - {getDate(contact.slot_start)} from
                  {getTimeSlot(contact.slot_start)} to {getTimeSlot(contact.slot_end)}
                </p>
              </div>
            ) : null}
            <div className="popup-message margin-top">
              <div className="flex-container">
                <EmailOutlinedIcon color="primary" fontSize="small" />
                <p className="text--sm">&nbsp; Message</p>
              </div>

              <p className="semi-bold">{contact.message}</p>
            </div>
          </div>
        </SwipeableDrawer>
      )}
      <style jsx>
        {`
          .popup {
            z-index: 9999;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(5px);
          }
          .popup-content {
            background-color: white;
            padding: 10px 40px 40px 40px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            position: fixed;
            width: 60%;
          }
          .dateTime {
            margin-top: -10px;
          }
          .popup-details {
            justify-content: space-between;
          }
          .close {
            position: absolute;
            top: 15px; /* Adjust top position as needed */
            right: 15px; /* Adjust right position as needed */
            font-size: 20px;
            cursor: pointer;
            color: var(--primary-color);
            padding: 0px 7px;
            border: 1px solid var(--primary-color);
            border-radius: 50%;
          }
          .close:hover {
            color: var(--error-color);
            border: 1px solid var(--error-color);
          }
          .semi-bold {
            font-weight: 500;
            color: black;
          }

          @media only screen and (max-width: 650px) {
            .popup-details {
              flex-direction: column;
              align-items: start;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MessagePopup;
