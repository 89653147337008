import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import Button from '@mui/material/Button';
import Popup from '../shared/components/Popup';
import Payment from '../shared/components/payment/Payment';
import { getPlanDetails, setAutoRenewal } from '../helper/api.helper';
import { useEffect } from 'react';

const Plan = () => {
  const [isOpen, setOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState(false);
  const user = useStoreState((state) => state.user);
  const userData = useStoreState((state) => state.dashboard.userData);
  const allPlans = useStoreState((state) => state.dashboard.plans);
  const plan = allPlans?.find((plan) => plan.id === userData?.plans[0]);

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const setAutoRenewalStatus = (status) => {
    toast.promise(
      setAutoRenewal({ status }).then(() =>
        getPlanDetails().then((res) => {
          setPlanDetails(res.data.data);
        })
      ),
      {
        loading: 'Updating subscription',
        success: 'Success!',
        error: 'Failed!'
      }
    );
  };

  useEffect(() => {
    if (userData?.is_paid && !planDetails) {
      getPlanDetails().then((res) => {
        setPlanDetails(res.data.data);
      });
    }
  }, [userData?.is_paid]);

  return (
    <div className="container">
      <Helmet>
        <title>Plan - PracticeID</title>
      </Helmet>
      <div className="margin-top--sm flex-container align-center header">
        <p>Plan</p>
        {!userData?.is_paid ? (
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Upgrade plan
          </Button>
        ) : planDetails && !planDetails.autoRenewal ? (
          <Button onClick={() => setAutoRenewalStatus(true)} variant="contained" color="primary">
            Enable subscription
          </Button>
        ) : (
          <Button onClick={() => setAutoRenewalStatus(false)} color="error">
            Cancel subscription
          </Button>
        )}
      </div>
      <hr />
      <div className="content margin-top--md">
        <div className="flex-container align-center">
          <p className="plan-name text--md">{plan?.name}</p> :{' '}
          <p style={{ marginLeft: '10px' }}>{userData?.email}</p>
        </div>
        {userData?.is_paid ? (
          <div className="flex-container align-center margin-top--sm">
            <p className="plan-name text--md">Renewal date</p> :{' '}
            <p style={{ marginLeft: '10px' }}>{new Date(userData?.end_time).toDateString()}</p>
          </div>
        ) : null}
        <Popup isOpen={isOpen} toggleOpen={toggleDrawer}>
          <div className="wrapper">
            <Payment upgrading token={user?.uid} />
          </div>
        </Popup>
      </div>
      <style jsx>{`
        .container {
          padding: 10px;
        }
        .header {
          justify-content: space-between;
        }
        .wrapper {
          padding: 10px;
        }
      `}</style>
    </div>
  );
};

export default Plan;
