import { ThemeProvider, createTheme } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import LoginMethods from './LoginMethods/LoginMethods';
import TopNav from '../shared/components/TopNav/TopNav';
import { Route, Routes } from 'react-router-dom';

const Login = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <TopNav />
      <Routes>
        <Route index element={<LoginMethods />} />
        <Route path="/forgot-password/*" element={<ForgotPassword />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Login;
