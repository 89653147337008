import React from 'react';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
  return (
    <div>
      <h2>Payment success!</h2>
      <p>
        Please proceed to{' '}
        <Link style={{ color: 'var(--primary-color)' }} to="/dashboard/overview">
          dashboard
        </Link>{' '}
        to customize
      </p>
    </div>
  );
};

export default PaymentSuccess;
