import { useNavigate } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import { useStoreActions } from 'easy-peasy';

const Plan = () => {
  const navigate = useNavigate();
  const setStep = useStoreActions((actions) => actions.register.setStep);

  const choosePlan = (planName) => {
    if (planName === 'free') {
      navigate('/dashboard/overview');
    } else {
      setStep(4);
    }
  };

  return (
    <section className="wrapper">
      <div className="plan-container flex-container text--sm">
        <div className="plan free-plan">
          <div className="heading">
            <h2>Free</h2>
            {/* <h3 className="margin-top--md margin-bottom--xs">
              Ideal for therapists who are early in their career.
            </h3> */}
            {/* <div className="price">FREE</div> */}
            <Button
              variant="outlined"
              size="small"
              onClick={() => choosePlan('free')}
              style={{
                width: '150px',
                margin: '0px auto',
                marginTop: '70px'
              }}>
              Select
            </Button>
          </div>
          <div className="line" />
          <div className="plan-body">
            <ul>
              <li className="flex-container align-center">
                <CheckIcon style={{ width: '20px', marginRight: '7px' }} />
                Sub-domain
              </li>
              <li className="flex-container align-center">
                <CheckIcon style={{ width: '20px', marginRight: '7px' }} />
                Regular hosting
              </li>
              <li className="flex-container align-center">
                <CheckIcon style={{ width: '20px', marginRight: '7px' }} />
                SSL Certificate
              </li>
              {/* <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />

                <span>
                  Original Theme
                  <div className="note">
                    (+ Additional themes separately chargeable - Coming soon)
                  </div>
                </span>
              </li> */}
              <li className="flex-container align-center">
                <CheckIcon style={{ width: '20px', marginRight: '7px' }} />
                Contact Form
              </li>
              {/* <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />7
                &apos;Contact me&apos; Responses per month - Coming soon
              </li>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                HIPAA compliant
              </li> */}
            </ul>
          </div>
        </div>
        <div className="plan paid">
          <div className="offer">Introductory pricing for the first 100 users</div>
          <div className="heading">
            <h2 className="margin-top--xxs">Professional</h2>
            {/* <h3 className="margin-top--md margin-bottom--xs">
                Ideal for independent private practitioners.
              </h3> */}
            <div className="price">
              Just $9 per year<p className="helper-text text--xs">(yup, per year)</p>
            </div>

            <Button
              variant="contained"
              size="small"
              onClick={() => choosePlan('paid')}
              color="primary"
              style={{
                width: '150px',
                margin: '0px auto'
              }}>
              Select
            </Button>
          </div>
          <div className="line" />
          <div className="plan-body">
            <ul>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                Link Custom Domain
              </li>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                Advanced hosting
              </li>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                SSL Certificate
              </li>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                Contact Form
              </li>
              {/* <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                <span>
                  Original Theme
                  <div className="note">
                    (+ Additional themes separately chargeable - Coming soon)
                  </div>
                </span>
              </li>
              <li className="flex-container align-center">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                HIPAA compliant
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrapper {
          padding: 2%;
        }
        .plan-container {
          justify-content: space-evenly;
          margin: auto;
          min-width: 340px;
          flex-wrap: wrap;
        }
        .plan {
          background-color: #fff;
          max-width: 300px;
          width: 44%;
          min-width: 256px;
          border-radius: 20px;
          margin: 1em;
          box-shadow:
            7px 7px 21px #e3e3e3,
            -7px -7px 21px #ffffff;
          color: #000000;
          overflow: hidden;
        }
        .free-plan {
          color: var(--gray-light);
        }
        .paid {
          border: 0.5px solid var(--primary-color);
        }
        .offer {
          background-color: var(--primary-color);
          color: white;
          padding: 10px 5px;
          text-align: center;
        }
        @media only screen and (max-width: 500px) {
          .paid {
            order: 1;
          }
          .free-plan {
            order: 2;
          }
        }

        .heading {
          padding: 2em 1.5em 0em 1.5em;
          min-height: 180px;
          text-align: center;
        }
        .free-plan .heading {
          min-height: 212px;
        }
        .billing {
          font-size: 0.5em;
          font-weight: normal;
        }
        h2 {
          font-size: 2em;
        }
        h3 {
          font-size: 1em;
        }
        .price {
          font-weight: 600;
          font-size: 1.7em;
          min-height: 70px;
          color: var(--primary-color);
        }
        .helper-text {
          color: gray;
          font-weight: normal;
        }
        .coming-soon {
          color: var(--brand-color);
          background-color: white;
          box-shadow: none;
          text-align: left;
          font-weight: bold;
        }
        .line {
          background-color: #f0eeef;
          height: 2px;
        }
        .plan-body {
          padding: 0.5em 1.5em 0em 1.5em;
        }
        ul {
          padding: 1.1em;
          list-style-type: none;
        }
        li {
          margin-bottom: var(--space-md);
          font-weight: 500;
        }
        .note {
          font-size: 0.75em;
          font-weight: normal;
        }
        .link {
          color: var(--brand-color);
          text-decoration: underline;
          cursor: pointer;
        }
        .get-started {
          font-size: 1rem;
          margin: 5% 0%;
          display: block;
          width: 150px;
          text-align: center;
        }
      `}</style>
    </section>
  );
};

export default Plan;
