export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const loadScript = (src, position, id) => {
  return new Promise((resolve) => {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.onload = () => resolve();
    script.src = src;
    position.appendChild(script);
  });
};

export const randomString = (len, an) => {
  an = an && an.toLowerCase();
  let str = '';
  let i = 0;
  const min = an === 'a' ? 10 : 0;
  const max = an === 'n' ? 10 : 62;
  for (; i++ < len; ) {
    let r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
};

export const getFileExtension = (fileName) => {
  return fileName.split('.').pop().trim();
};

export const removeEmptyKeys = (obj) => {
  Object.keys(obj).forEach(
    (k) => (obj[k] === null || obj[k] === undefined || obj[k] === '') && delete obj[k]
  );
  return obj;
};
