import { useSearchParams } from 'react-router-dom';
import Plan from './Plan';

const SelectPlan = () => {
  const [searchParams] = useSearchParams();

  return (
    <div>
      <Plan token={searchParams.get('token')} billingCycle={'monthly'} />
    </div>
  );
};

export default SelectPlan;
