import { AppBar, Tab, Tabs } from '@mui/material';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import Availability from './Availability/Availability';
import Events from './Events/Events';
import AddEvent from './Events/AddEvent';

const Meeting = () => {
  return (
    <>
      {window.innerWidth < 960 && (
        <AppBar position="sticky" color="inherit" elevation={2}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="nav tabs">
            <Tab
              component={NavLink}
              label="Availability"
              value="availability"
              to="/dashboard/meeting/availability"
            />
            <Tab component={NavLink} label="Events" value="events" to="/dashboard/meeting/events" />
          </Tabs>
        </AppBar>
      )}
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route index element={<Navigate to="availability" replace />} />

          <Route path="availability/*" element={<Availability />} />
          <Route path="events/*" element={<Events />} />
          <Route path="events/new/*" element={<AddEvent />} />
          <Route path="events/:id/*" element={<AddEvent />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Meeting;
