import { database, storage, auth } from '../../../helper/firebase.helper';
import { getFileExtension } from '../../../helper/util.helper';

const flattenArrayOfObj = (dataArr = []) => {
  return dataArr.length > 0 ? dataArr.map((data) => data.text) : [];
};

const convertToArrObjects = (strArray = []) => {
  return strArray.length > 0 ? strArray.map((data) => ({ text: data })) : [];
};

const uploadImage = async (imgName, newImageData, practitionerId, oldImageUrl) => {
  const imageRef = storage
    .ref()
    .child(`users/${practitionerId}/assets/${imgName}.${getFileExtension(newImageData.file.name)}`);
  await imageRef.put(newImageData.file);
  const imgUrl = await imageRef.getDownloadURL();
  return {
    name: imgName,
    cropData: { ...newImageData.cropData },
    newImageUrl: imgUrl,
    ...(oldImageUrl && { oldImageUrl })
  };
};

export const storePersonalToDb = async (data) => {
  const user = auth.currentUser;
  let profilePicData;
  let featurePicData;
  if (data.profilePic || data.featurePic) {
    const profilePicRef =
      data.profilePic &&
      storage
        .ref()
        .child(
          `users/${user.uid}/assets/profilePic.${getFileExtension(data.profilePic.file.name)}`
        );
    const featurePicRef =
      data.featurePic &&
      storage
        .ref()
        .child(
          `users/${user.uid}/assets/featurePic.${getFileExtension(data.featurePic.file.name)}`
        );
    await Promise.all([
      profilePicRef && profilePicRef.put(data.profilePic.file),
      featurePicRef && featurePicRef.put(data.featurePic.file)
    ]);
    const publicUrls = await Promise.all([
      profilePicRef && profilePicRef.getDownloadURL(),
      featurePicRef && featurePicRef.getDownloadURL()
    ]);

    if (profilePicRef) {
      profilePicData = {
        name: 'profilePic',
        cropData: { ...data.profilePic.cropData },
        newImageUrl: publicUrls[0],
        ...(data.profilePicUrl && { oldImageUrl: data.profilePicUrl })
      };
      data.profilePicUrl = publicUrls[0];
      delete data.profilePic;
    }
    if (featurePicRef) {
      featurePicData = {
        name: 'featurePic',
        cropData: { ...data.featurePic.cropData },
        newImageUrl: publicUrls[1],
        ...(data.featurePicUrl && { oldImageUrl: data.featurePicUrl })
      };
      data.featurePicUrl = publicUrls[1];
      delete data.featurePic;
    }
  }
  await Promise.all([
    featurePicData &&
      database
        .collection('practitioners')
        .doc(user.uid)
        .collection('crop_image')
        .add(featurePicData),
    profilePicData &&
      database
        .collection('practitioners')
        .doc(user.uid)
        .collection('crop_image')
        .add(profilePicData),
    database.doc(`practitioners/${user.uid}/details/personal`).set(data),
    database.doc(`practitioners/${user.uid}/details/progress`).set(
      {
        personal: true
      },
      { merge: true }
    )
  ]);
};

export const storeEmploymentToDb = async (data) => {
  const user = auth.currentUser;
  await Promise.all([
    database.doc(`practitioners/${user.uid}/details/employment`).set(data),
    database.doc(`practitioners/${user.uid}/details/progress`).set(
      {
        employment: true
      },
      { merge: true }
    )
  ]);
};

export const storeEducationToDb = async (data) => {
  const user = auth.currentUser;
  await Promise.all([
    database.doc(`practitioners/${user.uid}/details/education`).set(data),
    database.doc(`practitioners/${user.uid}/details/progress`).set(
      {
        education: true
      },
      { merge: true }
    )
  ]);
};

export const getPersonalData = () => {
  const user = auth.currentUser;
  return database
    .doc(`practitioners/${user.uid}/details/personal`)
    .get()
    .then((res) => {
      const data = res.data();
      return data;
    });
};

export const getEmploymentData = () => {
  const user = auth.currentUser;
  return database
    .doc(`practitioners/${user.uid}/details/employment`)
    .get()
    .then((res) => {
      const data = res.data();
      return data || { employment: [{}] };
    });
};

export const getEducationData = () => {
  const user = auth.currentUser;
  return database
    .doc(`practitioners/${user.uid}/details/education`)
    .get()
    .then((res) => {
      const data = res.data();
      return data || { education: [{}] };
    });
};

export const storeMyPracticeToDb = async (myPracticeData) => {
  const data = myPracticeData;
  const user = auth.currentUser;
  const imgCropQueue = [];
  data.insurances = flattenArrayOfObj(data.insurances);
  data.rates = flattenArrayOfObj(data.rates);
  data.licenses = flattenArrayOfObj(data.licenses);
  data.teleHealth = flattenArrayOfObj(data.teleHealth);
  Object.keys(data.social).forEach((key) => {
    data.social[key] = data.social[key] ?? '';
  });
  const uploadTask = await Promise.all([
    data.logo && uploadImage('logo', data.logo, user.uid, data.logoUrl),
    data.practiceImg && uploadImage('practiceImg', data.practiceImg, user.uid, data.practiceImgUrl),
    ...(data.physicalLocations
      ?.map((location, index) => {
        if (location[`storePic${index}`]) {
          return uploadImage(
            `storePic${index}`,
            location[`storePic${index}`],
            user.uid,
            location[`storePic${index}Url`]
          );
        }
        return false;
      })
      ?.filter(Boolean) || [])
  ]);
  uploadTask.forEach((task) => {
    if (task) {
      if (task.name === 'logo') {
        delete data.logo;
        data.logoUrl = task.newImageUrl;
        imgCropQueue.push(task);
      } else if (task.name === 'practiceImg') {
        delete data.practiceImg;
        data.practiceImgUrl = task.newImageUrl;
        imgCropQueue.push(task);
      } else if (task.name.includes('storePic')) {
        const locationIndex = task.name.split('storePic').pop();
        delete data.physicalLocations[locationIndex][`storePic${locationIndex}`];
        data.physicalLocations[locationIndex][`storePic${locationIndex}Url`] = task.newImageUrl;
        imgCropQueue.push(task);
      }
    }
  });

  await Promise.all([
    ...imgCropQueue.map((imgData) =>
      database.collection('practitioners').doc(user.uid).collection('crop_image').add(imgData)
    ),
    database.doc(`practitioners/${user.uid}/details/my_practice`).set(data),
    database.doc(`practitioners/${user.uid}/details/progress`).set(
      {
        my_practice: true
      },
      { merge: true }
    )
  ]);
};

export const getMyPracticeData = async () => {
  const user = auth.currentUser;
  return database
    .doc(`practitioners/${user.uid}/details/my_practice`)
    .get()
    .then((res) => {
      const data = res.data();
      data.insurances = convertToArrObjects(data.insurances);
      data.rates = convertToArrObjects(data.rates);
      data.licenses = convertToArrObjects(data.licenses);
      data.teleHealth = convertToArrObjects(data.teleHealth);
      return data;
    });
};
