/* eslint-disable no-undef */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';

import StripePayment from './StripePayment';
import { subscribeToPlan } from '../../../helper/api.helper';
import Loader from '../loader/Loader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const PAID_PLAN = 'YLtEQtWFbDUJjrR7GjxEXg24bvt2';

const Payment = ({ upgrading = false, token }) => {
  const [address, setAddress] = useState({
    completed: false,
    address: ''
  });
  const { tempId } = useParams();
  const [paymentValid, setPaymentValid] = useState(false);
  const [elements, setElements] = useState();
  const [options, setOptions] = useState({
    mode: 'subscription',
    amount: 900,
    currency: 'usd',
    setup_future_usage: 'off_session',
    automatic_payment_methods: {
      enabled: true
    }
  });
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState();
  const [paymentStatus, setPaymentStatus] = useState(0);

  const handleAddressChange = (e) => {
    setAddress({
      completed: e.complete,
      address: e.value?.address
    });
  };

  const handlePaymentChange = (e) => {
    setPaymentValid(e.complete);
  };

  const handleSubscribe = async () => {
    setLoading(true);
    toast.loading('Subscribing...');
    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.dismiss();
      setLoading(false);
      toast.error(submitError.message);
      return;
    }

    subscribeToPlan({
      billingAddress: address.address,
      plans: [PAID_PLAN],
      token: token ?? searchParams.get('token')
    })
      .then((res) => {
        stripe
          .confirmPayment({
            elements,
            clientSecret: res.data.clientSecret,
            confirmParams: {
              return_url: upgrading
                ? `https://${window.location.host}/dashboard/plan`
                : `https://${window.location.host}/register/demo/${tempId}?step=5`
            }
          })
          .then((res) => {
            if (res.error) {
              setLoading(false);
              if (!upgrading) setPaymentStatus(2);
              toast.dismiss();
              toast.error(res.error.message);
            }
            setLoading(false);
            if (!upgrading) setPaymentStatus(1);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            if (!upgrading) setPaymentStatus(2);
            toast.dismiss();
          });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setPaymentStatus(2);
        toast.dismiss();
      });
  };

  useEffect(() => {
    setOptions({
      mode: 'subscription',
      amount: address.address?.country === 'IN' ? 59000 : 900,
      currency: address.address?.country === 'IN' ? 'inr' : 'usd',
      setup_future_usage: 'off_session',
      automatic_payment_methods: {
        enabled: true
      }
    });
  }, [address]);

  return (
    <div>
      {loading ? <Loader /> : null}
      {paymentStatus === 0 ? (
        <>
          <Elements stripe={stripePromise} options={options}>
            <StripePayment
              setAddress={handleAddressChange}
              setElements={setElements}
              setPayment={handlePaymentChange}
              setStripe={setStripe}
            />
          </Elements>
          <Button
            onClick={handleSubscribe}
            disabled={!address.completed || !paymentValid}
            fullWidth
            variant="contained">
            Pay {address.address?.country === 'IN' ? '590 ₹' : '9 USD'}
          </Button>
        </>
      ) : null}
      {paymentStatus === 1 ? <h1>Payment success</h1> : null}
      {paymentStatus === 2 ? <h1>Payment failed</h1> : null}
    </div>
  );
};

export default Payment;
