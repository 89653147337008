import { Avatar, IconButton } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import Cropper from 'react-easy-crop';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '../../../../assets/upload.svg';

const UploadPic = ({
  imageUrl,
  aspectRatioWidth = 16,
  aspectRatioHeight = 9,
  helperText,
  name,
  maxWidth = 'initial',
  setCropData,
  arrayBuffer = false
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [file, setFile] = useState();
  const [uploadableFile, setUploadableFile] = useState();

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCropData({ cropData: croppedAreaPixels, file: uploadableFile });
    },
    [uploadableFile, setCropData]
  );

  const handleFileChange = async (e) => {
    if (e.target.files[0]?.size > 5242880) {
      toast.error(`File size shouldn't exceed 5MB`);
      e.target.files = '';
      return;
    }
    if (e.target.files[0]) {
      const reader = new FileReader();
      const bufferReader = new FileReader();
      reader.onload = (res) => {
        setFile(res.target.result);
      };
      bufferReader.onload = (res) => {
        setUploadableFile(
          arrayBuffer
            ? { name: e.target.files[0].name, data: res.target.result }
            : e.target.files[0]
        );
      };
      reader.readAsDataURL(e.target.files[0]);
      bufferReader.readAsBinaryString(e.target.files[0]);
    }
  };

  const reset = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setFile();
  };

  useEffect(() => {
    reset();
  }, [imageUrl]);

  return (
    <div className="container">
      <fieldset className="upload-wrapper">
        <legend>{name}</legend>
        {!file ? (
          <div
            className="full-width"
            style={{
              aspectRatio: `${aspectRatioWidth}/${aspectRatioHeight}`
            }}>
            <img className="image" src={imageUrl || UploadIcon} alt="Upload" />
          </div>
        ) : (
          <>
            <div className="crop-container">
              <Cropper
                image={file}
                crop={crop}
                zoom={zoom}
                objectFit="vertical-cover"
                aspect={aspectRatioWidth / aspectRatioHeight}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </>
        )}
        <div className="badge-upload">
          <Avatar>
            <label htmlFor={`icon-button-file-${name}`}>
              <input
                onChange={handleFileChange}
                accept="image/*"
                id={`icon-button-file-${name}`}
                type="file"
                hidden
              />
              <IconButton aria-label="upload picture" component="span">
                <EditIcon />
              </IconButton>
            </label>
          </Avatar>
        </div>
      </fieldset>
      {helperText.map((text) => (
        <p key={text} className="text--xs margin-top--xs helper-text gray-text">
          {text}
        </p>
      ))}
      <style jsx>{`
        .container {
          min-width: 150px;
          text-align: center;
        }
        .badge-upload {
          width: 1rem;
          height: 1rem;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .crop-container {
          position: relative;
          width: 100%;
          aspect-ratio: ${aspectRatioWidth} / ${aspectRatioHeight};
        }
        .button-container {
          justify-content: space-evenly;
        }
        .helper-text {
          text-align: left;
        }
        .image {
          min-height: 100px;
          aspect-ratio: ${aspectRatioWidth} / ${aspectRatioHeight};
        }
        legend {
          text-align: left;
        }
        .upload-wrapper {
          border: 1px solid rgb(211 211 211);
          border-radius: 4px;
          margin-left: 1px;
          position: relative;
          max-width: ${maxWidth};
        }
      `}</style>
    </div>
  );
};

export default UploadPic;
