import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ContactsList from './ContactsList';
import RefreshIcon from '@mui/icons-material/Refresh';

const Inbox = () => {
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="container   inbox-container">
      <Helmet>
        <title>Inbox - PracticeID</title>
      </Helmet>
      <div className="flex-container text--md  outer-container">
        <div className="icon-div"></div>
        <div className="email-div ">
          <p className="heading-color">Email</p>
        </div>
        <div className="source-div">
          <p className="heading-color">Source</p>
        </div>
        <div className="preview-div">
          <p className="heading-color">Preview</p>
        </div>
        <div className="actions-div flex-container justify-between">
          <p className="heading-color">Actions</p>
          <span onClick={() => setRefresh(!refresh)}>
            <RefreshIcon color="primary" style={{ cursor: 'pointer' }} />
          </span>
        </div>
      </div>
      <ContactsList key={refresh} />

      <style jsx>
        {`
          .inbox-container {
            padding: 20px;
          }
          .icon-div {
            width: 4%;
          }
          .email-div {
            width: 17%;
            overflow: hidden;
          }
          .source-div {
            width: 17%;
          }
          .preview-div {
            width: 37%;
          }
          .actions-div {
            width: 20%;
          }
          .heading-color {
            color: var(--gray-light);
          }
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .inner-div {
            color: var(--gray-darkest);
          }

          @media only screen and (max-width: 900px) {
            .email-div {
              width: 40%;
            }
            .source-div {
              display: none;
            }
            .preview-div {
              display: none;
            }
            .actions-div {
              width: 40%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Inbox;
