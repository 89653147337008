import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

import Personal from './steps/Personal';
import Education from './steps/Education';
import Employment from './steps/Employment';
import { generateWebsite } from '../../helper/api.helper';
import TopNav from '../../shared/components/TopNav/TopNav';
import Loader from '../../shared/components/loader/Loader';
import toast from 'react-hot-toast';

const GetInfo = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [infoData, setInfoData] = useState();
  const [loading, setLoading] = useState(false);

  const goNext = (data, key) => {
    setInfoData((preValue) => ({
      ...preValue,
      [key]: data
    }));
    if (activeTab === 2) {
      return;
    }
    setActiveTab((currTab) => currTab + 1);
  };

  const getItemAfterCollapse = () => {
    if (activeTab === 0) {
      return 0;
    } else if (activeTab === 1) {
      return 2;
    } else {
      return 2;
    }
  };

  const getItemBeforeCollapse = () => {
    if (activeTab === 0) {
      return 2;
    } else if (activeTab === 1) {
      return 0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (activeTab === 2 && infoData.employment) {
      setLoading(true);
      toast.loading('Generating website. This might take a couple of minutes.');
      generateWebsite(infoData)
        .then((res) => {
          toast.dismiss();
          if (res.data.data.tempPractId) {
            navigate(`/register/demo/${res.data.data.tempPractId}`);
          }
        })
        .catch((err) => {
          toast.dismiss();
          setLoading(false);
          toast.error(`Please try again. message: ${err.message}`);
        });
    }
  }, [activeTab, infoData]);

  return (
    <div>
      {loading ? <Loader /> : null}
      <TopNav />
      <div className="about-wrapper">
        <Breadcrumbs
          maxItems={window.innerWidth < 500 ? 2 : 4}
          itemsBeforeCollapse={window.innerWidth < 500 ? getItemBeforeCollapse() : 1}
          itemsAfterCollapse={window.innerWidth < 500 ? getItemAfterCollapse() : 1}
          separator={<hr style={{ width: '9vw' }} />}
          aria-label="steps">
          <div className="flex-container align-center">
            {activeTab > 0 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Personal
          </div>
          <div className="flex-container align-center">
            {activeTab > 1 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Education
          </div>
          <div className="flex-container align-center">
            {activeTab > 2 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Employment
          </div>
        </Breadcrumbs>
        <div className="container margin-top--lg">
          {activeTab === 0 ? (
            <Personal goNext={goNext} />
          ) : activeTab === 1 ? (
            <Education goNext={goNext} />
          ) : (
            <Employment goNext={goNext} />
          )}
        </div>
      </div>
      <style jsx>{`
        .about-wrapper {
          min-height: 70vh;
          max-width: 700px;
          margin: auto;
          margin-top: 10vh;
          text-align: center;
          flex-direction: column;
          padding: 10px;
        }
        .container {
          box-shadow: 0px 0 6px #e5e4e6;
          padding: 7%;
          border-radius: 10px;
        }
      `}</style>
      <style>{`        
        .about-wrapper .MuiBreadcrumbs-ol {
          justify-content: center !important;
        }`}</style>
    </div>
  );
};

export default GetInfo;
