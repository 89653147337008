import axios from 'axios';

import { auth } from './firebase.helper';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://127.0.0.1:5001/practitioner-website/us-central1/api/';

export const makePost = async (url, data) => {
  const token = await auth.currentUser?.getIdToken(true);
  return axios({
    url: `${BASE_URL}${url}`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      authorization: `token ${token}`
    }
  });
};

export const makeGet = async (url, headers = {}) => {
  const token = await auth.currentUser?.getIdToken(true);
  return axios({
    url: `${BASE_URL}${url}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `token ${token}`,
      ...headers
    }
  });
};

export const createAccount = (data) => {
  const url = 'register/create-account';
  return makePost(url, data);
};

export const confirmPlan = (data) => {
  const url = 'register/confirm-plan';
  return makePost(url, data);
};

export const updateProfile = (data) => {
  const url = 'psychologist/account/details';
  return makePost(url, data);
};

export const getProfile = () => {
  const url = 'psychologist/account/details';
  return makeGet(url);
};

export const generateWebsite = (data) => {
  const url = 'register/demo-website';
  return makePost(url, { data });
};

export const subscribeToPlan = (data) => {
  const url = 'register/subscribe';
  return makePost(url, data);
};

export const getPlanDetails = () => {
  const url = 'user/plan';
  return makeGet(url);
};

export const setAutoRenewal = (data) => {
  const url = 'user/plan/auto-renewal';
  return makePost(url, data);
};
