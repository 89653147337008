/* eslint-disable no-undef */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();
const analytics = firebase.analytics();
const performance = firebase.performance();

export { auth, database, performance, storage, firebase, analytics };
