import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { storeMeetingToDb, getEventById, updateEvent } from '../shared/meeting.service';
import toast from 'react-hot-toast';

const AddEvent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    title: '',
    desc: '',
    time: ''
  });

  const maxLength = 90;
  const remainingCharacters = maxLength - formData.desc.length;

  useEffect(() => {
    if (id) {
      getEventById(id).then((event) => {
        if (event) {
          setFormData({
            title: event.title || '',
            desc: event.desc || '',
            time: event.time || ''
          });
        }
      });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newEvent = {
      title: formData.title,
      desc: formData.desc,
      time: formData.time,
      creation_time: Date.now()
    };
    if (id) {
      await toast.promise(updateEvent(id, newEvent), {
        loading: 'Updating...',
        success: 'Updated!',
        error: `Couldn't update`
      });
    } else {
      await toast.promise(storeMeetingToDb(newEvent), {
        loading: 'Saving...',
        success: 'Saved!',
        error: `Couldn't save`
      });
    }
    setFormData({ title: '', desc: '', time: '' });
    navigate('/dashboard/meeting/events');
  };

  return (
    <div style={{ maxWidth: '500px' }}>
      <div style={{ margin: '20px 0px 0px 8px', fontSize: '14px' }}>
        <b>{id ? 'Edit Event' : 'Add New Event'}</b>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex-container direction-column">
          <TextField
            size="small"
            name="title"
            label="Event Name"
            value={formData.title}
            onChange={handleChange}
            required
            sx={{
              marginBottom: '1rem',
              marginTop: '1rem'
            }}
          />
          <TextField
            size="small"
            name="time"
            label="Duration"
            select
            value={formData.time}
            onChange={handleChange}
            required
            sx={{ marginBottom: '1rem' }}>
            <MenuItem value={15}>15 mins</MenuItem>
            <MenuItem value={30}>30 mins</MenuItem>
            <MenuItem value={45}>45 mins</MenuItem>
            <MenuItem value={60}>60 mins</MenuItem>
          </TextField>
          <TextField
            size="small"
            name="desc"
            label="Description"
            multiline
            rows={4}
            value={formData.desc}
            onChange={handleChange}
            // required
            inputProps={{ maxLength: maxLength }}
            sx={{
              marginBottom: '1rem'
            }}
          />
          <small style={{ marginTop: '-10px', fontSize: '12px' }}>
            Character count {formData.desc.length}/90. {remainingCharacters} characters remaining.
          </small>

          <div className="flex-container justify-between margin-top--md">
            <Link to="/dashboard/meeting/events">
              <Button variant="outlined" color="primary">
                Cancel
              </Button>
            </Link>

            <Button type="submit" variant="contained" color="primary">
              {id ? 'Update Event' : 'Add Event'}
            </Button>
          </div>
        </div>
      </form>
      <style jsx>{``}</style>
    </div>
  );
};

export default AddEvent;
