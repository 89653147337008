import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';

import GetInfo from './get-info/GetInfo';
import Demo from './demo/Demo';

const Register = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<GetInfo />} />
        <Route path="/about-me" element={<GetInfo />} />
        <Route path="/demo/:tempId" element={<Demo />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Register;
