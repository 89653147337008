import { auth, database } from '../../helper/firebase.helper.js';
import { collection, query, where, getDocs, startAfter, limit, orderBy } from 'firebase/firestore';

const PAGE_SIZE = 10;

export const fetchContacts = async (lastVisible) => {
  const user = auth.currentUser;
  if (user) {
    const contactsRef = collection(database, 'contacts');
    let q = query(
      contactsRef,
      where('practitioner_email', '==', user.email),
      orderBy('creation_time', 'desc'),
      limit(PAGE_SIZE)
    );

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    const querySnapshot = await getDocs(q);

    let dataArray = [];
    querySnapshot.forEach((doc) => {
      dataArray.push({ ...doc.data() });
    });

    if (dataArray.length < PAGE_SIZE) {
      // If the fetched data is less than the page size, it means there are no more pages
      return {
        data: dataArray,
        hasMore: false,
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
      };
    }

    return {
      data: dataArray,
      hasMore: true,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
  }
  return { data: [], hasMore: false, lastVisible: null };
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    month: 'short',
    day: 'numeric'
  };
  const optionsYear = {
    year: 'numeric'
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);
  const formattedYear = date.toLocaleString(undefined, optionsYear);
  const formattedTime = date.toLocaleString(undefined, optionsTime);

  const formattedDateTime = `${formattedDate}, ${formattedYear} at ${formattedTime}`;

  return formattedDateTime;
};

export const getDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);

  const formattedDateTime = `${formattedDate} `;

  return formattedDateTime;
};

export const getTimeSlot = (timestamp) => {
  const date = new Date(timestamp);

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const formattedTime = date.toLocaleString(undefined, optionsTime);

  const formattedDateTime = ` ${formattedTime}`;

  return formattedDateTime;
};
