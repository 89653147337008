import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import {
  Fab,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { getBranding, storeBranding } from './shared/website.service';

const BrandGuidelines = () => {
  const [brandingData, setBrandingData] = useState();
  const { handleSubmit, control, reset, setValue } = useForm();

  const onSubmit = async (data) => {
    await toast.promise(storeBranding(data), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't to save`
    });
    getBranding().then((res) => setBrandingData(res));
  };

  useEffect(() => {
    getBranding().then((res) => setBrandingData(res));
  }, []);

  useEffect(() => {
    reset(brandingData);
  }, [brandingData, reset]);

  return (
    <div className="container">
      <Helmet>
        <title>Branding guidelines- PracticeID</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Typography</p>
        <hr />
        <div className="field margin-top margin-bottom">
          <Controller
            name="font"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="font-family-selector">Font family</InputLabel>
                <Select
                  label="Font family"
                  labelId="font-family-selector"
                  value={value}
                  onChange={onChange}
                  error={!!error}>
                  <MenuItem value="Roboto">Roboto</MenuItem>
                  {/* <MenuItem value='Arial'>Arial</MenuItem>
                  <MenuItem value='Verdana'>Verdana</MenuItem>
                  <MenuItem value='Helvetica'>Helvetica</MenuItem>
                  <MenuItem value='Times New Roman'>Times New Roman</MenuItem>
                  <MenuItem value='Courier New'>Courier New</MenuItem> */}
                </Select>
                <FormHelperText>
                  {error
                    ? error.message
                    : 'Tip: Avoid fancy/decorative fonts which can make it difficult for your clients to read your website. '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{ required: 'Please fill this field' }}
          />
        </div>
        <p>Color palette</p>
        <hr />
        <div className="field margin-top margin-bottom--xs">
          <Controller
            name="primaryColor"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <label htmlFor="color-selector">
                <input
                  onChange={onChange}
                  value={value}
                  id="color-selector"
                  className="color-selector"
                  type="color"
                />
                <TextField
                  label="Primary color"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={value}
                  fullWidth
                  error={!!error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ backgroundColor: value }} className="color-preview" />
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    error
                      ? error.message
                      : 'Tip: Use a dark shade of your brand color palette to attract attention of your clients to take action on the website.'
                  }
                />
              </label>
            )}
          />
        </div>
        <div className="floating-button">
          <Fab style={{ width: '120px' }} variant="extended" color="primary" type="submit">
            Save
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .container {
          padding: 10px;
        }
        .floating-button {
          position: fixed;
          bottom: 30px;
          right: 10%;
        }
        .field {
          max-width: 500px;
          position: relative;
        }
        .color-selector {
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          z-index: 999;
        }
        .color-preview {
          height: 25px;
          width: 25px;
          border: 1px solid black;
          border-radius: 3px;
        }
        @media only screen and (max-width: 600px) {
          .floating-button {
            bottom: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default BrandGuidelines;
