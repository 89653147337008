/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useStoreState } from 'easy-peasy';

import TopBanner from './TopBanner';
import Popup from '../../shared/components/Popup';
import CreateAccount from './create-account/CreateAccount';
import Loader from '../../shared/components/loader/Loader';
import { useSearchParams } from 'react-router-dom';

const Demo = () => {
  const { tempId } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const step = useStoreState((state) => state.register.step);
  const [searchParams] = useSearchParams();

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const handleClick = (e) => {
    toggleDrawer(true)(e);
  };

  const helpText =
    step === 1
      ? 'Create account to customise your website.'
      : step === 2
      ? 'Verify your email to customise your website.'
      : step === 3
      ? 'Pick a plan to publish your website'
      : step === 4
      ? 'Please complete payment to proceed'
      : 'Login to dashboard to customize website';

  useEffect(() => {
    if (searchParams.get('step') == 3 || searchParams.get('step') == 5) {
      setOpen(true);
    }
  }, []);

  return (
    <div className="container flex-container direction-column">
      {!iframeLoaded ? <Loader /> : null}
      <TopBanner helpText={helpText} openDrawer={toggleDrawer(true)} step={step} />
      <div className="iframe-wrapper">
        <iframe
          height="800px"
          width="100%"
          title="Website demo"
          onLoad={handleIframeLoad}
          src={`${process.env.REACT_APP_PREVIEW_DOMAIN}?tempId=${tempId}`}></iframe>
        <div className="overlay" onClick={handleClick} />
      </div>
      <Popup isOpen={isOpen} toggleOpen={toggleDrawer}>
        <CreateAccount helpText={helpText} closePopUp={toggleDrawer(false)} />
      </Popup>
      <style jsx>{`
        .container {
          height: 100vh;
        }
        .iframe-wrapper {
          height: fill-available;
          position: relative;
        }
        iframe {
          border: none;
          pointer-events: none;
          min-height: 100vh !important;
        }
        .overlay {
          position: absolute;
          height: 800px;
          width: 100%;
          top: 0;
        }
        @media only screen and (max-width: 1100px) {
          iframe {
            height: max(130vh, 1200px);
          }
          .overlay {
            height: max(130vh, 1200px);
          }
        }
      `}</style>
    </div>
  );
};

export default Demo;
