import React, { useEffect, useState } from 'react';
import MessagePopup from './MessagePopup.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchContacts } from './inbox.service.js';
import Contact from './Contact.jsx';

const ContactsList = () => {
  const [contacts, setContacts] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [popup, setPopup] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const {
      data: newData,
      hasMore: newHasMore,
      lastVisible: newLastVisible
    } = await fetchContacts(lastVisible);

    if (!newHasMore) {
      setHasMore(false);
    }
    if (newData.length > 0) {
      const updatedContacts = contacts ? [...contacts, ...newData] : newData;
      setContacts(updatedContacts);
      setLastVisible(newLastVisible);
    }
  };

  //handle popup open function
  const popupOpen = (message) => {
    setSelectedMessage(message);
    setPopup(!popup);
    setOpen(true);
  };

  //handle popup close function
  const popupClose = () => {
    setSelectedMessage(null);
    setPopup(!popup);
  };

  const toggleDrawer = (value) => () => {
    setOpen(value);
    setPopup(!popup);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={contacts ? contacts.length : 0}
        next={fetchData}
        hasMore={hasMore}
        loader={<p className="optional-heading margin-top">Loading...</p>}
        endMessage={
          contacts && contacts.length > 0 ? (
            <p className="optional-heading margin-top"> That&apos;s All !</p>
          ) : (
            <p className="optional-heading margin-top">No Messages Yet !</p>
          )
        }>
        {contacts &&
          contacts.map((data) => (
            <Contact key={data.creation_time} popupOpen={popupOpen} contact={data} />
          ))}
      </InfiniteScroll>
      {popup && selectedMessage && (
        <MessagePopup
          contact={selectedMessage}
          popupClose={popupClose}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      )}

      <style jsx>
        {`
          .optional-heading {
            text-align: center;
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default ContactsList;
