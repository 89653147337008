import { action } from 'easy-peasy';

const register = {
  step: 1,
  setStep: action((state, payload) => {
    state.step = payload;
  })
};

export default register;
