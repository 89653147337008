import { Button, Hidden } from '@mui/material';
import WhiteLogo from '../../assets/practiceid-white.svg';

const TopBanner = ({ openDrawer, helpText, step }) => {
  return (
    <div className="wrapper">
      <div className="row flex-container align-center">
        <img src={WhiteLogo} className="logo" alt="PracticeID" />
        <Hidden smDown>
          <p>{helpText}</p>
        </Hidden>
        <Button
          onClick={step == 5 ? undefined : openDrawer}
          href={step == 5 ? '/dashboard/overview' : undefined}
          style={{ minWidth: '150px', backgroundColor: 'white' }}
          variant="outlined">
          {step == 5 ? 'Dashboard' : 'Customise'}
        </Button>
      </div>
      <p className="preview text--sm">This is a preview of your website</p>
      <style jsx>{`
        .wrapper {
          background-color: var(--primary-color);
        }
        .row {
          justify-content: space-between;
          padding: 25px 3% 5px 3%;
          color: white;
        }
        .logo {
          width: 150px;
        }
        .preview {
          background-color: white;
          margin: auto;
          font-weight: 500;
          padding: 3px 4%;
          width: fit-content;
          border-radius: 7px 7px 0 0;
        }
      `}</style>
    </div>
  );
};

export default TopBanner;
