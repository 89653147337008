import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';

import store from './store';
import '../src/app/styles/index.scss';
import App from './App';

const root = document.getElementById('root');

createRoot(root).render(
  <StoreProvider store={store}>
    <StrictMode>
      <App />
    </StrictMode>
  </StoreProvider>
);
