import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Loader from './app/shared/components/loader/Loader';
import Login from './app/Login/Login';
import Dashboard from './app/Dashboard/Dashboard';
import Register from './app/Register/Register';
import RouteGuard from './app/RouteGuard';
import { auth } from './app/helper/firebase.helper';
import { getUserData } from './app/Dashboard/dashboard.service';

function App() {
  const [isLoading, setLoading] = useState(true);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setUserData = useStoreActions((actions) => actions.dashboard.setUserData);
  const setPlans = useStoreActions((actions) => actions.dashboard.setPlans);
  const user = useStoreState((state) => state.user);
  const userData = useStoreState((state) => state.dashboard.userData);
  const isUserAuthenticated = user && user.emailVerified && userData && userData.plans?.length;

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const [userData, plans] = await getUserData(user.uid);
        setPlans(plans);
        setUserData(userData);
      } else {
        setUserData(null);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      {isLoading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route index element={<Navigate to="login" replace />} />
            <Route
              path="/login/*"
              element={
                <RouteGuard
                  isAuthenticated={!isUserAuthenticated}
                  redPath="/dashboard"
                  component={Login}
                />
              }
            />
            <Route path="/register/*" element={<Register />} />
            <Route
              path="/dashboard/*"
              element={
                <RouteGuard
                  isAuthenticated={isUserAuthenticated}
                  redPath="/login"
                  component={Dashboard}
                />
              }
            />
          </Routes>
        </Router>
      )}
      <Toaster position="bottom-center" />
    </Suspense>
  );
}
export default App;
