import { TextField, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LinkIcon from '@mui/icons-material/Link';
import GetLocation from '../../../shared/components/get-location/GetLocation';
import StartIcon from '@mui/icons-material/Start';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

const EmploymentItem = ({ item, index, control, setValue }) => {
  const { isCurrentlyWorking } = item;

  const [ongoing, setOngoing] = useState(isCurrentlyWorking);

  const validateEndMonth = (fieldValue, values) => {
    const startMonth = values['employment'][index].startMonth;
    if (!ongoing && startMonth && fieldValue) {
      const startTime = new Date();
      startTime.setMonth(Number(startMonth.split('-')[1]) - 1);
      startTime.setFullYear(Number(startMonth.split('-')[0]));
      const endTime = new Date();
      endTime.setMonth(Number(fieldValue.split('-')[1]) - 1);
      endTime.setFullYear(Number(fieldValue.split('-')[0]));
      return endTime.getTime() > startTime.getTime()
        ? true
        : 'End month has to be greater than start month';
    } else if (!startMonth && ongoing && fieldValue) {
      return false;
    } else if (startMonth && !ongoing && !fieldValue) {
      return 'Please fill end month';
    }
    return true;
  };

  return (
    <div className="container">
      <h4>Employment {index + 1}</h4>
      <hr />
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.profession`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Profession/Role"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BadgeIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              required
            />
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.company`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Company/Employer"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <GetLocation
          label="Company/Employer Location"
          defaultValue={item?.location}
          addressSelected={(e) => setValue(`employment.${index}.location`, e?.description)}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.website`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Company/Employer Website"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.startMonth`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Start month"
              type="month"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StartIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>

      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.isCurrentlyWorking`}
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Checkbox
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
                setValue(`employment.${index}.endMonth`, '');
                setOngoing(!ongoing);
              }}
            />
          )}
        />
        <span>I am currently working here</span>
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`employment.${index}.endMonth`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              label="End month"
              type="month"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyboardTabIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              disabled={ongoing}
            />
          )}
          rules={{
            validate: validateEndMonth
          }}
        />
      </div>
      <style jsx>{`
        .field {
          max-width: 600px;
        }
      `}</style>
    </div>
  );
};

export default EmploymentItem;
