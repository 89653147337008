import React, { useState, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';

import style from './loginmail.module.scss';
import { auth } from '../../helper/firebase.helper';

// eslint-disable-next-line react/display-name
const LoginMail = memo(({ validateUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signIn = () => {
    toast('Logging in...');
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        validateUser(user);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('loginEmail')) {
      setEmail(sessionStorage.getItem('loginEmail'));
      sessionStorage.removeItem('loginEmail');
    }
  }, []);

  return (
    <form className={style.form} autoComplete="off">
      <div className={style.field}>
        <TextField
          required
          label="Email"
          variant="outlined"
          id="email"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={(e) => setEmail(e.target.value.trim())}
          style={{ width: '100%' }}
        />
      </div>
      <div className={style.field}>
        <TextField
          required
          type="password"
          label="Password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ width: '100%' }}
          autoComplete="current-password"
        />
      </div>
      <Link to="forgot-password" className={style.forgot}>
        Forgot password
      </Link>
      <div className={style.field}>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          size="large"
          color="primary"
          onClick={signIn}>
          Sign in
        </Button>
      </div>
    </form>
  );
});

export default LoginMail;
