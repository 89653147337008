import { createStore, action } from 'easy-peasy';
import register from './app/Register/register.store';
import dashboard from './app/Dashboard/dashboard.store';
const store = createStore({
  user: undefined,
  register,
  dashboard,
  setUser: action((state, payload) => {
    state.user = payload;
  })
});

export default store;
