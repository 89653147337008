import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Fab } from '@mui/material';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import EmploymentItem from './EmploymentItem';
import { getEmploymentData, storeEmploymentToDb } from '../shared/profile.service';
import { removeEmptyKeys } from '../../../helper/util.helper';

const Employment = () => {
  const { handleSubmit, control, reset, setValue } = useForm();
  const {
    fields: employments,
    append: addEmployment,
    remove: removeEmployment
  } = useFieldArray({
    control,
    name: 'employment'
  });

  const onSubmit = async (data) => {
    const formattedData = data.employment.map((item) => removeEmptyKeys(item));
    await toast.promise(storeEmploymentToDb({ employment: formattedData }), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't save`
    });
    getEmploymentData().then((res) => reset(res));
  };

  useEffect(() => {
    getEmploymentData().then((res) => reset(res));
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>Employment - PracticeID</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        {employments.map((employment, index) => (
          <div key={employment.id}>
            <EmploymentItem control={control} item={employment} index={index} setValue={setValue} />
            {/* <Button variant="text" color='warning' onClick={() => removeEmployment(index)}>Remove</Button> */}
          </div>
        ))}
        {/* <Button variant="text" onClick={addEmployment}>Add more</Button> */}
        <div className="floating-button">
          <Fab style={{ width: '120px' }} variant="extended" color="primary" type="submit">
            Save
          </Fab>
        </div>
      </form>
      <style jsx>
        {`
          .container {
            padding: 0px 10px;
            padding-bottom: 30px;
          }
          .floating-button {
            position: fixed;
            bottom: 60px;
            right: 10%;
          }
        `}
      </style>
    </div>
  );
};

export default Employment;
