import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import InputAdornment from '@mui/material/InputAdornment';
import GetLocation from '../../../shared/components/get-location/GetLocation';

const Employment = ({ goNext }) => {
  const { handleSubmit, setValue, control } = useForm();

  const onSubmit = (data) => {
    goNext(data, 'employment');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="margin-top--xs margin-bottom--md full-width">
        <Controller
          name="profession"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Profession/Role"
              variant="outlined"
              size="small"
              value={value}
              required
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BadgeIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>
      <div className="margin-top--xs margin-bottom--md full-width">
        <Controller
          name="company"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Company/Employer"
              variant="outlined"
              size="small"
              value={value}
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </div>
      <div className="margin-top--xs margin-bottom--md full-width">
        <GetLocation
          label="Company/Employer Location"
          defaultValue=""
          addressSelected={(e) => setValue('location', e?.description)}
        />
      </div>
      <Button fullWidth variant="contained" color="primary" type="submit">
        <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
      </Button>
    </form>
  );
};

export default Employment;
