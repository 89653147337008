import { useState, Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import Profile from './profile/Profile';
import MessageIcon from '@mui/icons-material/Message';
import { AppBar } from '@mui/material';
import TopNav from '../shared/components/TopNav/TopNav';
import Sidebar from './Shared/components/sidebar/Sidebar';
import styles from './dashboard.module.scss';
import Website from './website/Website';
import Overview from './Overview';
import Domain from './Domain';
import HomeIcon from '@mui/icons-material/Home';
import clsx from 'clsx';
import Loader from '../shared/components/loader/Loader';
import Plan from './Plan';
import Inbox from './Inbox/Inbox';
import Meeting from './Meeting/Meeting';

const Dashboard = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  const [sidebar, setSidebar] = useState(window.innerWidth > 960);

  const handleSidebar = () => {
    setSidebar((s) => !s);
  };

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <div className={styles.wrapper}>
          <TopNav showUser handleSidebar={handleSidebar} />
          <div id="dashboard-container" className={styles.homeContent}>
            <Sidebar {...{ sidebar, handleSidebar }} />
            <div className={styles.homeContainer}>
              <Routes>
                <Route index element={<Navigate to="overview" replace />} />
                <Route path="/overview/*" element={<Overview />} />
                <Route path="/inbox/*" element={<Inbox />} />
                <Route path="/domain/*" element={<Domain />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/meeting/*" element={<Meeting />} />
                <Route path="/style/*" element={<Website />} />
                <Route path="/plan/*" element={<Plan />} />
              </Routes>
            </div>
          </div>
          {window.innerWidth < 960 && (
            <AppBar position="sticky" style={{ zIndex: 2, bottom: '0px' }} color="inherit">
              <div className={styles.bottom_navigator}>
                <NavLink
                  className={({ isActive }) => clsx(styles.nav, isActive && styles.active)}
                  to="/dashboard/overview">
                  <HomeIcon />
                  <span>Overview</span>
                </NavLink>
                <NavLink
                  className={({ isActive }) => clsx(styles.nav, isActive && styles.active)}
                  to="/dashboard/inbox">
                  <MessageIcon />
                  <span>Inbox</span>
                </NavLink>
              </div>
            </AppBar>
          )}
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default Dashboard;
