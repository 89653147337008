import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import PersonIcon from '@mui/icons-material/Person';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';

import UploadPic from '../../../Dashboard/Shared/components/upload-pic/UploadPic';

const Personal = ({ goNext }) => {
  const { handleSubmit, setValue, control } = useForm();

  const setCroppedImage = (imageName) => (cropData) => {
    setValue(imageName, cropData);
  };

  const onSubmit = (data) => {
    goNext(data, 'personal');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="margin-bottom--lg">
        <Controller
          name="profilePicUrl"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <UploadPic
              name="Profile picture"
              helperText={[]}
              aspectRatioWidth={1}
              aspectRatioHeight={1}
              maxWidth="150px"
              setCropData={setCroppedImage('profilePic')}
              imageUrl={value}
              arrayBuffer
            />
          )}
        />
      </div>
      <div className="margin-top--xs margin-bottom--md full-width">
        <Controller
          name="fullName"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              value={value}
              required
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>
      <div className="margin-top--xs margin-bottom--md full-width">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={value}
              required
              fullWidth
              onChange={onChange}
              error={!!error}
              type="email"
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>
      <Button fullWidth variant="contained" color="primary" type="submit">
        <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
      </Button>
    </form>
  );
};

export default Personal;
