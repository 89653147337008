/* eslint-disable no-undef */
import { Fab } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Style, Domain } from '@mui/icons-material';
import VideocamIcon from '@mui/icons-material/Videocam';

import ProgressBar from '../shared/components/progressbar/ProgressBar';
import {
  getProgressInfo,
  setWebsiteState,
  setAcceptingClients,
  getDomain
} from './website/shared/website.service';
import Step from './Shared/components/Step';

const Overview = () => {
  const [progressData, setProgressData] = useState({});
  const [incompleteMandatorySteps, setIncompleteMandatorySteps] = useState(0);
  const [subDomain, setSubDomain] = useState();

  const getSiteDomain = async () => {
    const domain = await getDomain().then((res) => res.siteDomain);
    setSubDomain(domain);
  };

  const getProgressData = () => {
    getProgressInfo()
      .then((resData) => {
        if (resData?.published) {
          getSiteDomain();
        }
        let stepsCompleted = 0;
        Object.keys(resData).forEach((key) => {
          if (key !== 'published' && key !== 'acceptingClients' && resData[key]) {
            stepsCompleted++;
          }
        });
        const data = {
          stepsCompleted,
          acceptingClients: resData.acceptingClients,
          isPublished: resData.published,
          steps: [
            {
              icon: <AccountCircle />,
              title: 'Profile/Personal',
              isMandatory: true,
              isComplete: resData.personal,
              link: '/dashboard/profile/personal'
            },
            {
              icon: <AccountCircle />,
              title: 'Profile/Employment',
              isMandatory: true,
              isComplete: resData.employment,
              link: '/dashboard/profile/employment'
            },
            {
              icon: <AccountCircle />,
              title: 'Profile/Education',
              isMandatory: false,
              isComplete: resData.education,
              link: '/dashboard/profile/education'
            },
            {
              icon: <Style />,
              title: 'Style/Branding-guidelines',
              isMandatory: false,
              isComplete: resData.branding,
              link: '/dashboard/style/brand-guidelines'
            },
            {
              icon: <Domain />,
              title: 'Domain',
              isMandatory: true,
              isComplete: resData.domain,
              link: '/dashboard/domain'
            },
            {
              icon: <VideocamIcon />,
              title: 'Meeting Settings',
              isMandatory: false,
              isComplete: resData.meeting,
              link: '/dashboard/meeting'
            }
          ]
        };
        setProgressData(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getProgressData();
  }, []);

  useEffect(() => {
    setIncompleteMandatorySteps(
      progressData?.steps?.some((step) => step.isMandatory && !step.isComplete)
    );
  }, [progressData]);

  const togglePublish = async () => {
    await toast.promise(setWebsiteState(!progressData.isPublished), {
      loading: progressData.isPublished ? 'Unpublishing...' : 'Publishing...',
      success: !progressData.isPublished ? 'Published' : 'Unpublished',
      error: `Couldn't publish`
    });
    getProgressData();
  };

  const toggleAcceptingClients = async (isAccepting) => {
    await toast.promise(setAcceptingClients(isAccepting), {
      loading: 'Saving...',
      success: isAccepting ? 'Accepting new clients' : 'Not accepting clients',
      error: `Couldn't update`
    });
    getProgressData();
  };

  return (
    <div className="container">
      <Helmet>
        <title>Overview - PracticeID</title>
      </Helmet>
      {/* <div className='flex-container align-center margin-top--xs margin-bottom--xs accepting-clients'>
        <p>Accepting new clients</p>
        <Switch
          color='primary'
          checked={!!progressData.acceptingClients}
          value={!!progressData.acceptingClients}
          onChange={(e) => toggleAcceptingClients(e.target.checked)}
        />
      </div> */}
      <p className="text--md margin-bottom--xxs">
        {incompleteMandatorySteps ? (
          `You are ${6 - progressData.stepsCompleted} steps away from publishing your
        PracticeID`
        ) : progressData.isPublished ? (
          <p>
            Your PracticeID is published -{' '}
            <a
              className="link"
              href={`https://${subDomain}.${process.env.REACT_APP_WEBSITE_DOMAIN}`}
              target="_blank"
              rel="noreferrer">
              https://{subDomain}.{process.env.REACT_APP_WEBSITE_DOMAIN}
            </a>
          </p>
        ) : (
          `Click Publish button below to publish your website.`
        )}
      </p>
      <ProgressBar total={6} current={progressData.stepsCompleted} />
      <div className="steps-container">
        <Step steps={progressData?.steps} />
      </div>
      <div className="floating-button">
        <Fab
          style={{ width: '120px' }}
          variant="extended"
          disabled={!!incompleteMandatorySteps && !progressData.isPublished}
          color={progressData.isPublished ? 'secondary' : 'primary'}
          type="button"
          onClick={togglePublish}>
          {progressData.isPublished ? 'Unpublish' : 'Publish'}
        </Fab>
      </div>
      <style jsx>{`
        .container {
          padding: 10px;
        }
        .accepting-clients {
          justify-content: flex-end;
        }
        .floating-button {
          position: fixed;
          bottom: 30px;
          right: 10%;
        }
        .link {
          text-decoration: underline;
          color: var(--primary-color);
        }
        @media only screen and (max-width: 600px) {
          .floating-button {
            bottom: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default Overview;
