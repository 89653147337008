import { auth, database, storage } from '../../../helper/firebase.helper';

export const storeMeetingToDb = async (data) => {
  const user = auth.currentUser;
  await Promise.all([
    database.collection(`practitioners/${user.uid}/details/meeting/events`).add(data)
    // database.doc(`practitioners/${user.uid}/details/progress`).set(
    //   {
    //     meeting: true
    //   },
    //   { merge: true }
    // )
  ]);
};

export const storeAvailabilityToDb = async (data, practitionerTimezone) => {
  const user = auth.currentUser;
  await Promise.all([
    database
      .doc(`practitioners/${user.uid}/details/meeting`)
      .set({ availability: data, timezone: practitionerTimezone }),
    database.doc(`practitioners/${user.uid}/details/progress`).set(
      {
        meeting: true
      },
      { merge: true }
    )
  ]);
};

export const getEventsData = async () => {
  const user = auth.currentUser;
  const eventsSnapshot = await database
    .collection(`practitioners/${user.uid}/details/meeting/events`)
    .orderBy('creation_time', 'desc')
    .get();

  const events = [];
  eventsSnapshot.forEach((doc) => {
    events.push({
      id: doc.id,
      ...doc.data()
    });
  });

  return events;
};

export const deleteEventFromDb = async (eventId) => {
  const user = auth.currentUser;
  await database
    .collection(`practitioners/${user.uid}/details/meeting/events`)
    .doc(eventId)
    .delete();
};

export const getEventById = async (id) => {
  try {
    const user = auth.currentUser;
    const docRef = await database
      .doc(`practitioners/${user.uid}/details/meeting/events/${id}`)
      .get();
    if (docRef.exists) {
      return { id: docRef.id, ...docRef.data() };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
};

export const updateEvent = async (id, eventData) => {
  try {
    const user = auth.currentUser;
    await database.doc(`practitioners/${user.uid}/details/meeting/events/${id}`).update(eventData);
    return true;
  } catch (error) {
    console.error('Error updating document:', error);
    return false;
  }
};

export const getAvailabilityFromDb = async () => {
  const user = auth.currentUser;
  try {
    const doc = await database.doc(`practitioners/${user.uid}/details/meeting`).get();
    if (doc.exists) {
      return doc.data().availability;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting availability:', error);
    return null;
  }
};

export const timeSlotOption = [
  '12:00 AM',
  '12:15 AM',
  '12:30 AM',
  '12:45 AM',
  '1:00 AM',
  '1:15 AM',
  '1:30 AM',
  '1:45 AM',
  '2:00 AM',
  '2:15 AM',
  '2:30 AM',
  '2:45 AM',
  '3:00 AM',
  '3:15 AM',
  '3:30 AM',
  '3:45 AM',
  '4:00 AM',
  '4:15 AM',
  '4:30 AM',
  '4:45 AM',
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM'
];

export const timeSlotOption1 = [
  '12:15 AM',
  '12:30 AM',
  '12:45 AM',
  '1:00 AM',
  '1:15 AM',
  '1:30 AM',
  '1:45 AM',
  '2:00 AM',
  '2:15 AM',
  '2:30 AM',
  '2:45 AM',
  '3:00 AM',
  '3:15 AM',
  '3:30 AM',
  '3:45 AM',
  '4:00 AM',
  '4:15 AM',
  '4:30 AM',
  '4:45 AM',
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
  '12:00 AM'
];
