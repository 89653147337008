import { database, auth } from '../../../helper/firebase.helper';

export const storeDomain = async (domainData) => {
  await Promise.all([
    database.doc(`practitioners/${auth.currentUser?.uid}/details/domain`).set(domainData),
    database.doc(`practitioners/${auth.currentUser?.uid}/details/progress`).set(
      {
        domain: true
      },
      { merge: true }
    )
  ]);
};

export const storeBranding = async (brandingData) => {
  await Promise.all([
    database.doc(`practitioners/${auth.currentUser?.uid}/details/branding`).set(brandingData),
    database.doc(`practitioners/${auth.currentUser?.uid}/details/progress`).set(
      {
        branding: true
      },
      { merge: true }
    ),
    ...(brandingData.siteDomain
      ? [
          database.doc(`practitioners/${auth.currentUser?.uid}/details/progress`).set(
            {
              domain: true
            },
            { merge: true }
          )
        ]
      : [])
  ]);
};

export const getBranding = () => {
  return database
    .doc(`practitioners/${auth.currentUser?.uid}/details/branding`)
    .get()
    .then((data) => data.data());
};

export const getDomain = () => {
  return database
    .doc(`practitioners/${auth.currentUser?.uid}/details/domain`)
    .get()
    .then((data) => data.data());
};

export const getProgressInfo = () => {
  return database
    .doc(`practitioners/${auth.currentUser?.uid}/details/progress`)
    .get()
    .then((data) => data.data())
    .catch((er) => console.error(er));
};

export const setWebsiteState = (isPublished) => {
  return database.doc(`practitioners/${auth.currentUser?.uid}/details/progress`).set(
    {
      published: isPublished
    },
    { merge: true }
  );
};

export const setAcceptingClients = (isAccepting) => {
  console.log(isAccepting);
  return database.doc(`practitioners/${auth.currentUser?.uid}/details/progress`).set(
    {
      acceptingClients: isAccepting
    },
    { merge: true }
  );
};

export const checkForDomain = (domain) => {
  return database
    .collectionGroup(`details`)
    .where('siteDomain', '==', domain)
    .limit(1)
    .get()
    .then((res) => {
      return res.empty;
    });
};
