import { AppBar, Tab, Tabs } from '@mui/material';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import Personal from './personal/Personal';
import Employment from './employment/Employment';
import Education from './education/Education';

const Profile = () => {
  return (
    <>
      {window.innerWidth < 960 && (
        <AppBar position="sticky" color="inherit" elevation={2}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="nav tabs">
            <Tab
              component={NavLink}
              label="Personal"
              value="personal"
              to="/dashboard/profile/personal"
            />
            <Tab
              component={NavLink}
              label="Employment"
              value="employment"
              to="/dashboard/profile/employment"
            />
            <Tab
              component={NavLink}
              label="Education"
              value="education"
              to="/dashboard/profile/education"
            />
          </Tabs>
        </AppBar>
      )}
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route index element={<Navigate to="personal" replace />} />
          <Route path="personal/*" element={<Personal />} />
          <Route path="employment/*" element={<Employment />} />
          <Route path="education/*" element={<Education />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Profile;
