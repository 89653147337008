import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import { getEventsData, deleteEventFromDb } from '../shared/meeting.service';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const Events = () => {
  const [events, setEvents] = useState();
  const [deleteEventId, setDeleteEventId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = (id) => {
    setDeleteEventId(id);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    await toast.promise(deleteEventFromDb(deleteEventId), {
      loading: 'Deleting...',
      success: 'Deleted!',
      error: `Couldn't delete`
    });

    const updatedEvents = events.filter((event) => event.id !== deleteEventId);
    setEvents(updatedEvents);
    setOpenDialog(false);
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false);
  };

  async function fetchdata() {
    const firestoreEvents = await getEventsData();
    setEvents(firestoreEvents);
    console.log(firestoreEvents);
  }

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <div className="flex-container direction-column">
      <div className="heading-div flex-container justify-between">
        <b>Event types</b>

        <Link to="/dashboard/meeting/events/new">
          <Button
            style={{ maxHeight: '30px', marginTop: '-5px' }}
            variant="contained"
            color="primary">
            Add New Event
          </Button>
        </Link>
      </div>

      <div className="flex-container align-center outer-div margin-top--lg">
        {events &&
          events.map((event, index) => (
            <div
              key={index}
              className="flex-container direction-column inner-div margin-bottom--lg">
              <div className="flex-container justify-between">
                <div className="flex-container">
                  <div>
                    <EventAvailableIcon color="primary" style={{ marginBottom: '-6px' }} />
                  </div>
                  <div style={{ marginLeft: '7px' }}>
                    <b style={{ color: 'black' }}>{event.title}</b>
                  </div>
                </div>
                <div className="flex-container">
                  <div>
                    <HourglassTopIcon color="primary" style={{ marginBottom: '-6px' }} />
                  </div>
                  <div>
                    <b style={{ color: 'black' }}> {event.time} mins</b>
                  </div>
                </div>
              </div>
              <div className="flex-container justify-between align-center desc-div">
                <div className="flex-container align-center">
                  <p>{event.desc}</p>
                </div>
              </div>
              <div className="flex-container justify-between">
                <IconButton
                  onClick={() => handleDelete(event.id)}
                  color="error"
                  sx={{ '&:hover': { backgroundColor: 'ButtonShadow' } }}>
                  <DeleteIcon />
                </IconButton>

                <Link to={`/dashboard/meeting/events/${event.id}`}>
                  <Button variant="contained" color="primary" sx={{ maxHeight: 30 }}>
                    Edit
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        {events?.length === 0 ? (
          <small>
            Event types are crucial for defining the purpose of your meetings, whether it&apos;s a
            15 min discovery call, a 1-hour deep dive discussion, or any other type. Setting up
            event types helps visitors understand the purpose of the meeting and facilitates
            smoother scheduling. Get started by setting up event types now!
          </small>
        ) : null}
      </div>

      <Dialog open={openDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
        }
        .outer-div {
          // width: 800px;
          flex-wrap: wrap;
          justify-content: flex-start;
          // align-content: center;
        }
        .inner-div {
          margin: 2%;
          min-height: 160px;
          max-width: 330px;
          width: 35%;
          height: 40%;
          border-top: 8px solid #097eff;
          border-radius: 5px;
          justify-content: space-around;
          padding: 20px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        }
        .desc-div {
          padding: 15px 10px;
          text-align: left;
        }
        h5 {
          margin-left: 10px;
        }
        @media only screen and (max-width: 765px) {
          .outer-div {
            flex-direction: column;
            // max-width: 330px;
          }
          .inner-div {
            width: 80%;
            // margin: 0%;
          }
        }
        @media only screen and (max-width: 310px) {
          .outer-div {
            max-width: 280px;
          }
          .inner-div {
            width: 85%;
            margin: 0px 0px 20px 0px;
          }
        }
      `}</style>
    </div>
  );
};

export default Events;
