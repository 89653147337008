import React from 'react';
import { useStoreState } from 'easy-peasy';
import styles from './account.module.scss';

export default function Account({ showUser = false }) {
  const user = useStoreState((state) => state.user);
  return (
    <div className={styles.userAccount}>
      {showUser && user && <span className={styles.userEmail}>{user.email}</span>}
    </div>
  );
}
