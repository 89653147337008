import { database, auth } from '../helper/firebase.helper';

export const checkUserData = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      return false;
    }

    const userData = await database
      .doc(`practitioners/${auth.currentUser?.uid}`)
      .get()
      .then((data) => data.data())
      .catch((er) => console.error(er));

    if (userData && userData.plans.length > 0) {
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error checking user data:', error);
    return false;
  }
};
