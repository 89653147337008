import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Fab } from '@mui/material';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { getEducationData, storeEducationToDb } from '../shared/profile.service';
import EducationItem from './EducationItem';
import { removeEmptyKeys } from '../../../helper/util.helper';

const Education = () => {
  const { handleSubmit, control, reset, setValue } = useForm();
  const {
    fields: educations,
    append: addEducation,
    remove: removeEducation
  } = useFieldArray({
    control,
    name: 'education'
  });

  const onSubmit = async (data) => {
    const formattedData = data.education.map((item) => removeEmptyKeys(item));
    await toast.promise(storeEducationToDb({ education: formattedData }), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't save`
    });
    getEducationData().then((res) => reset(res));
  };

  useEffect(() => {
    getEducationData().then((res) => reset(res));
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>Education - PracticeID</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        {educations.map((education, index) => (
          <div key={education.id}>
            <EducationItem control={control} item={education} index={index} setValue={setValue} />
            {/* <Button variant="text" color='warning' onClick={() => removeEducation(index)}>Remove</Button> */}
          </div>
        ))}
        {/* <Button variant="text" onClick={addEducation}>Add more</Button> */}
        <div className="floating-button">
          <Fab style={{ width: '120px' }} variant="extended" color="primary" type="submit">
            Save
          </Fab>
        </div>
      </form>
      <style jsx>
        {`
          .container {
            padding: 0px 10px;
            padding-bottom: 30px;
          }
          .floating-button {
            position: fixed;
            bottom: 60px;
            right: 10%;
          }
        `}
      </style>
    </div>
  );
};

export default Education;
