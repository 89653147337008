import React, { useEffect, memo, useState, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import EmailIcon from '@mui/icons-material/Email';
// import FacebookIcon from '@mui/icons-material/Facebook';
//import AppleIcon from '@mui/icons-material/Apple';
import { toast } from 'react-hot-toast';
import style from './LoginMethods.module.scss';
import { auth, firebase } from '../../helper/firebase.helper';
import googleIcon from '../../assets/google-icon.svg';
import LoginMail from '../LoginMail/LoginMail';
import Loader from '../../shared/components/loader/Loader';
import { checkUserData } from '../login.service';

// eslint-disable-next-line react/display-name
const LoginMethods = memo(() => {
  const setUser = useStoreActions((actions) => actions.setUser);
  const [isLoading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl');

  const validateUser = useCallback(
    async (res) => {
      await auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
        if (idTokenResult.claims.user_id) {
          setUser(res.user.multiFactor.user);
          if (idTokenResult.claims.email_verified) {
            const isUserDataValid = await checkUserData();
            if (isUserDataValid) {
              toast('Logged in');
              if (redirectUrl) {
                navigate(redirectUrl);
              } else {
                location.state && location.state.from
                  ? navigate(location.state.from)
                  : navigate('/dashboard/overview');
              }
            } else {
              toast.error('You do not have an account on PracticeID. Please Sign up.', {
                duration: 4000
              });
              auth.signOut();
            }
          } else {
            toast.error('Please verify email before logging in');
            auth.signOut();
          }
        } else {
          toast.error('Invalid user');
          auth.signOut();
        }
      });
    },
    [location, navigate, redirectUrl]
  );

  // Get the redirection login result
  useEffect(() => {
    if (!auth.currentUser) {
      auth
        .getRedirectResult()
        .then((res) => {
          setLoading(false);
          if (res.user) validateUser(res);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`Failed to login: ${err.message}`);
        });
    }
    if (localStorage.getItem('isLoggingIn')) {
      setLoading(true);
      localStorage.removeItem('isLoggingIn');
    }
  }, [validateUser]);

  const signInGmail = () => {
    setLoading(true);
    localStorage.setItem('isLoggingIn', true);
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: 'select_account'
    });
    auth.signInWithPopup(googleProvider);
  };

  // const signInWithFB = () => {
  //   const FBProvider = new firebase.auth.FacebookAuthProvider();
  //   FBProvider.addScope('email');
  //   auth.signInWithRedirect(FBProvider);
  // };

  const signInWithEmail = () => {
    setIsEmail(true);
  };

  return (
    <div className={style.page_wrapper}>
      <Helmet>
        <title>Login - PracticeID{''}</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <div className={style.wrapper}>
        <h1>Practitioner Sign in</h1>
        {isEmail ? (
          <LoginMail validateUser={validateUser} />
        ) : (
          <>
            <Button
              startIcon={<img className={style.logo} src={googleIcon} alt="Google" />}
              className={style.login_button}
              variant="contained"
              onClick={signInGmail}>
              Sign in with Google
            </Button>
            {/* <Button
              startIcon={
                <FacebookIcon
                  style={{
                    marginLeft: '15px',
                    fontSize: '25px',
                    color: '#4267B2',
                  }}
                />
              }
              className={style.login_button}
              variant='contained'
              onClick={signInWithFB}
            >
              Sign in with Facebook
            </Button> */}
            {/* <Button
              startIcon={
                <AppleIcon
                  style={{
                    marginLeft: '-13px',
                    fontSize: '25px'
                  }}
                />
              }
              className={style.login_button}
              variant='contained'
              onClick={signInWithApple}
            >
              Sign in with Apple
            </Button> */}
            <Button
              startIcon={<EmailIcon style={{ marginLeft: '-10px', fontSize: '25px' }} />}
              className={style.login_button}
              variant="contained"
              onClick={signInWithEmail}>
              Sign in with email
            </Button>
          </>
        )}
        <p className={style.create_account}>
          Don&apos;t have an account?{' '}
          <Link className={style.sign_up} to="/register/about-me">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
});

export default LoginMethods;
