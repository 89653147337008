import { useState } from 'react';
import { Button, Divider, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { auth, firebase } from '../../../helper/firebase.helper';
import googleIcon from '../../../assets/google-icon.svg';
import { createAccount } from '../../../helper/api.helper';
import Loader from '../../../shared/components/loader/Loader';

const RegisterEmail = ({ setStep }) => {
  const navigate = useNavigate();
  const { tempId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [eMail, setEMail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEMail = (event) => {
    setEMail(event.target.value.trim());
    if (!event.target.value || validateEmail(event.target.email)) {
      setErrors({
        ...errors,
        emailError: true
      });
    } else {
      setErrors({
        ...errors,
        emailError: false
      });
    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        passwordError: true
      });
    } else {
      setErrors({
        ...errors,
        passwordError: false
      });
    }
  };

  const submitDetails = () => {
    if (eMail && password) {
      createPractitionerAccount(eMail, password);
    } else if (!eMail || validateEmail(eMail)) {
      setErrors({
        ...errors,
        emailError: true
      });
    } else if (!password) {
      setErrors({
        ...errors,
        passwordError: true
      });
    }
  };

  const createPractitionerAccount = async (email, password) => {
    setLoading(true);
    toast.loading('Creating account');
    createAccount({
      email,
      password,
      tempId
    })
      .then(async (res) => {
        setLoading(false);
        toast.dismiss();
        if (res.data.status !== 'success') {
          toast.error(`Failed to register: ${res.data.message}`);
        }
        if (res.data.redirectUrl) {
          if (password) {
            await auth
              .signInWithEmailAndPassword(email, password)
              .then(() => {
                toast.success(`User present already. Signing in`);
              })
              .catch(() => {
                sessionStorage.setItem('loginEmail', email);
                toast.success(`User present already. Please sign in`);
              });
          } else {
            toast.success(`User present already. Signing in`);
          }
          navigate(res.data.redirectUrl);
          return;
        }
        toast.success('Email registered successfully!');
        if (password) {
          await auth.signInWithEmailAndPassword(email, password);
          sessionStorage.setItem(email, password);
        }
        setSearchParams(`?${new URLSearchParams({ email, token: res.data.uid })}`);
        await auth.currentUser?.reload();
        if (!res.data.emailVerified) {
          setStep(2);
        } else {
          setStep(3);
        }
      })
      .catch((err) => {
        toast.dismiss();
        setLoading(false);
        console.error(err);
        throw err;
      });
  };
  const validateUser = async () => {
    await auth.currentUser.getIdTokenResult().then((idTokenResult) => {
      if (!idTokenResult.claims.email) {
        toast.error(`Email is missing in the account selected. Please try with other account`);
        auth.signOut();
        setLoading(false);
        return;
      }
      createPractitionerAccount(idTokenResult.claims.email);
    });
  };

  const signUpGmail = () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: 'select_account'
    });
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        if (res.user) validateUser(res.user);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Failed to login: ${err.message}`);
      });
  };

  return (
    <div className="wrapper">
      {loading ? <Loader /> : null}
      <Button
        startIcon={
          <img
            style={{ height: '20px', width: '20px' }}
            className="logo"
            src={googleIcon}
            alt="Google"
          />
        }
        className="gmail-login-button"
        variant="contained"
        fullWidth
        onClick={signUpGmail}>
        Sign up with Google
      </Button>
      <Divider sx={{ mt: '20px' }}>
        <p className="text--sm">or continue with</p>
      </Divider>
      <TextField
        required
        size="small"
        fullWidth
        sx={{ marginTop: '15px' }}
        type="email"
        id="email"
        onChange={(e) => handleEMail(e)}
        label={<span className="text--sm">Email</span>}
        variant="outlined"
        error={errors.emailError}
        helperText={errors.emailError ? 'please fill the email in correct format' : ''}
      />
      <TextField
        required
        fullWidth
        sx={{ marginTop: '15px' }}
        size="small"
        id="password"
        onChange={(e) => handlePassword(e)}
        type="password"
        label={<span className="text--sm">Password</span>}
        variant="outlined"
        error={errors.passwordError}
        helperText={errors.passwordError ? 'Please enter password' : ''}
      />
      <Button
        sx={{ marginTop: '15px', fontSize: 'var(--text-sm)' }}
        variant="contained"
        fullWidth
        onClick={() => submitDetails()}
        color="primary">
        Register
      </Button>
      <style>{`
        .gmail-login-button {
          background-color: white;
          color: black;
          text-transform: none;
          padding: 10px;
          margin-top: 15px;
        }
      `}</style>
      <style jsx>{`
        .wrapper {
          max-width: 300px;
          margin: auto;
        }
      `}</style>
    </div>
  );
};

export default RegisterEmail;
