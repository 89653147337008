import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import SchoolIcon from '@mui/icons-material/School';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LinkIcon from '@mui/icons-material/Link';
import GetLocation from '../../../shared/components/get-location/GetLocation';
import StartIcon from '@mui/icons-material/Start';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

const EducationItem = ({ item, index, control, setValue }) => {
  const validateEndMonth = (fieldValue, values) => {
    const startMonth = values['education'][index].startMonth;
    if (startMonth && fieldValue) {
      const startTime = new Date();
      startTime.setMonth(Number(startMonth.split('-')[1]) - 1);
      startTime.setFullYear(Number(startMonth.split('-')[0]));
      const endTime = new Date();
      endTime.setMonth(Number(fieldValue.split('-')[1]) - 1);
      endTime.setFullYear(Number(fieldValue.split('-')[0]));
      return endTime.getTime() > startTime.getTime()
        ? true
        : 'End month has to be greater than start month';
    } else if (!startMonth && fieldValue) {
      return false;
    } else if (startMonth && !fieldValue) {
      return 'Please fill end month';
    }
    return true;
  };

  return (
    <div className="container">
      <h4>Education {index + 1}</h4>
      <hr />
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`education.${index}.education`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Education/Qualification"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SchoolIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`education.${index}.school`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="School/College"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <GetLocation
          label="School/College Location"
          defaultValue={item?.location}
          addressSelected={(e) => setValue(`education.${index}.location`, e?.description)}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`education.${index}.website`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="School/College Website"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`education.${index}.startMonth`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="Start month"
              type="month"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StartIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="field margin-top--md margin-bottom--md">
        <Controller
          name={`education.${index}.endMonth`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              label="End month (or expected)"
              type="month"
              helperText={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyboardTabIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          )}
          rules={{ validate: validateEndMonth }}
        />
      </div>
      <style jsx>{`
        .field {
          max-width: 600px;
        }
      `}</style>
    </div>
  );
};

export default EducationItem;
