import { NavLink, useMatch } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VideocamIcon from '@mui/icons-material/Videocam';
import DomainIcon from '@mui/icons-material/Domain';
import StyleIcon from '@mui/icons-material/Style';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import Accordion from '../../../../shared/components/accordion/Accordion';
import LogoContainer from '../../../../shared/components/logocontainers/logocontainer';
import styles from './Sidebar.module.scss';
import clsx from 'clsx';

export default function Sidebar({ sidebar, handleSidebar }) {
  const drawerWidth = 240;
  const DrawerHeader = styled(Drawer)({
    width: drawerWidth,
    flexShrink: 0,
    overflow: 'auto',
    '.MuiDrawer-paper': {
      width: drawerWidth,
      borderRight: 'none',
      top: 'auto'
    }
  });
  const profile = useMatch('/dashboard/profile/*');
  const meeting = useMatch('/dashboard/meeting/*');

  const style = useMatch('/dashboard/style/*');

  return (
    <DrawerHeader
      container={document.getElementById('dashboard-container')}
      variant={window.innerWidth > 960 ? 'permanent' : 'temporary'}
      anchor="left"
      open={sidebar}
      onClose={handleSidebar}
      hideBackdrop
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <LogoContainer handleSidebar={handleSidebar} />
        </div>
        <div className={styles.sidebar_list}>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/overview"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <HomeIcon />
            <div>Overview</div>
          </NavLink>

          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/inbox"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <MessageIcon />
            <div>Inbox</div>
          </NavLink>

          {window.innerWidth > 960 && sidebar ? (
            <div className={styles.sidebar_accordion}>
              <Accordion
                tab={1}
                Icon={AccountCircleIcon}
                Arrow={KeyboardArrowRightIcon}
                opened={profile}
                rotation={90}>
                <div className={styles.accordion_title}>Profile</div>
                <div className={styles.accordion_body}>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/profile/personal"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Personal
                  </NavLink>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/profile/employment"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Employment
                  </NavLink>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/profile/education"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Education
                  </NavLink>
                </div>
              </Accordion>
            </div>
          ) : (
            <NavLink
              role="link"
              className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
              to="/dashboard/profile"
              onClick={window.innerWidth < 960 ? handleSidebar : null}>
              <AccountCircleIcon />
              <div>Profile</div>
            </NavLink>
          )}

          {window.innerWidth > 960 && sidebar ? (
            <div className={styles.sidebar_accordion}>
              <Accordion
                tab={1}
                Icon={StyleIcon}
                Arrow={KeyboardArrowRightIcon}
                opened={style}
                rotation={90}>
                <div className={styles.accordion_title}>Style</div>
                <div className={styles.accordion_body}>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/style/brand-guidelines"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Brand Guidelines
                  </NavLink>
                </div>
              </Accordion>
            </div>
          ) : (
            <NavLink
              role="link"
              className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
              to="/dashboard/style"
              onClick={window.innerWidth < 960 ? handleSidebar : null}>
              <StyleIcon />
              <div>Style</div>
            </NavLink>
          )}
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/domain"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <DomainIcon />
            <div>Domain</div>
          </NavLink>
          {window.innerWidth > 960 && sidebar ? (
            <div className={styles.sidebar_accordion}>
              <Accordion
                tab={1}
                Icon={VideocamIcon}
                Arrow={KeyboardArrowRightIcon}
                opened={meeting}
                rotation={90}>
                <div className={styles.accordion_title}>Meeting</div>
                <div className={styles.accordion_body}>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/meeting/availability"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Availability
                  </NavLink>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/meeting/events"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Events
                  </NavLink>
                </div>
              </Accordion>
            </div>
          ) : (
            <NavLink
              role="link"
              className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
              to="/dashboard/meeting"
              onClick={window.innerWidth < 960 ? handleSidebar : null}>
              <VideocamIcon />
              <div>Meeting</div>
            </NavLink>
          )}
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/plan"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <CreditCardIcon />
            <div>Plan</div>
          </NavLink>
        </div>
      </div>
    </DrawerHeader>
  );
}
