import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import Title from './Title';
import RegisterEmail from './RegisterEmail';
import SelectPlan from './SelectPlan';
import VerifyEmail from './VerifyEmail';
import Payment from '../../../shared/components/payment/Payment';
import PaymentSuccess from '../../../shared/components/payment/PaymentSuccess';

const CreateAccount = ({ closePopUp, helpText }) => {
  const setStep = useStoreActions((actions) => actions.register.setStep);
  const step = useStoreState((state) => state.register.step);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('step') == 3) setStep(3);
    if (searchParams.get('step') == 5) setStep(5);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('step')) {
      setStep(1);
    }
  }, []);

  return (
    <div>
      <Title title={helpText} closePopUp={closePopUp} />
      {step === 1 ? <RegisterEmail setStep={setStep} /> : null}
      {step === 2 ? <VerifyEmail /> : null}
      {step === 3 ? <SelectPlan setStep={setStep} /> : null}
      {step === 4 ? <Payment setStep={setStep} /> : null}
      {step === 5 ? <PaymentSuccess /> : null}
    </div>
  );
};

export default CreateAccount;
