import { PaymentElement, useElements, AddressElement, useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

const StripePayment = ({ setPayment, setAddress, setElements, setStripe }) => {
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    setElements(elements);
  }, [elements]);

  useEffect(() => {
    setStripe(stripe);
  }, [stripe]);

  return (
    <div>
      <AddressElement onChange={setAddress} options={{ mode: 'billing' }} />
      <PaymentElement onChange={setPayment} />
    </div>
  );
};

export default StripePayment;
