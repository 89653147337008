import { action } from 'easy-peasy';

const dashboard = {
  userData: null,
  plans: null,
  setUserData: action((state, payload) => {
    state.userData = payload;
  }),
  setPlans: action((state, payload) => {
    state.plans = payload;
  })
};

export default dashboard;
