import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

const Title = ({ title, closePopUp }) => {
  return (
    <>
      <div className="row flex-container align-center">
        <p>{title}</p>
        <IconButton onClick={closePopUp} aria-label="close">
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </div>
      <hr />
      <style jsx>{`
        .row {
          justify-content: space-between;
          ${window.innerWidth > 960 ? '0' : 'padding: 15px 10px 0px 10px;'}
        }
      `}</style>
    </>
  );
};

export default Title;
