import { AppBar, Tab, Tabs } from '@mui/material';
import React from 'react';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import BrandGuidelines from './BrandGuidelines';

const Website = () => {
  return (
    <>
      {window.innerWidth < 960 && (
        <AppBar position="sticky" color="inherit" elevation={2}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="nav tabs">
            <Tab
              component={NavLink}
              label="Brand Guidelines"
              value="brand-guidelines"
              to="/dashboard/style/brand-guidelines"
            />
          </Tabs>
        </AppBar>
      )}
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route index element={<Navigate to="brand-guidelines" replace />} />
          <Route path="brand-guidelines/*" element={<BrandGuidelines />} />
          {/* <Route path='my-practice/*' element={<MyPractice />} /> */}
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Website;
