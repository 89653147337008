import React, { useMemo } from 'react';
import styles from './progressbar.module.scss';

export default function ProgressBar({ total, current }) {
  const progress = useMemo(() => (current / total) * 100, [current, total]);
  return (
    <div className={styles.progress_bar}>
      <div className={styles.bar}>
        <span
          className={styles.value}
          style={{
            width: `${progress}%`
          }}
        />
      </div>
      <div className={styles.label}>
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
}
