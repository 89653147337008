import { database } from '../helper/firebase.helper';

export const getUserData = async (uid) => {
  const [userData, plans] = await Promise.all([
    database
      .doc(`practitioners/${uid}`)
      .get()
      .then((res) => {
        return res.data();
      }),
    database
      .doc(`platform_data/plans`)
      .get()
      .then((res) => {
        return Object.keys(res.data()).map((planId) => ({
          id: planId,
          ...res.get(planId)
        }));
      })
  ]);
  return [userData, plans];
};
