import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import toast from 'react-hot-toast';
import UploadPic from '../../Shared/components/upload-pic/UploadPic';
import { getPersonalData, storePersonalToDb } from '../shared/profile.service';
import SocialLinks from './SocialLinks';

const Personal = () => {
  const [aboutMeData, setAboutMeData] = useState();
  const { handleSubmit, control, setValue, reset } = useForm();

  const setCroppedImage = (imageName) => (cropData) => {
    setValue(imageName, cropData);
  };

  const onSubmit = async (data) => {
    await toast.promise(storePersonalToDb(data), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't save`
    });
    getPersonalData().then((res) => setAboutMeData(res));
  };

  useEffect(() => {
    if (!aboutMeData) {
      getPersonalData().then((res) => {
        setAboutMeData(res);
      });
    }
  }, [aboutMeData]);

  useEffect(() => {
    reset(aboutMeData);
  }, [aboutMeData, reset]);
  return (
    <div className="container">
      <Helmet>
        <title>Personal - PracticeID</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile-pic-container margin-bottom--lg">
          <Controller
            name="profilePicUrl"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <UploadPic
                name="Profile picture"
                helperText={[
                  'Adjust the image to ensure the aspect ratio is 1:1. This helps improve your website performance like load times.',
                  'Tip: Use a professional picture. It helps in establishing credibility with the client.'
                ]}
                aspectRatioWidth={1}
                aspectRatioHeight={1}
                maxWidth="150px"
                setCropData={setCroppedImage('profilePic')}
                imageUrl={value}
              />
            )}
          />
        </div>
        <div className="form-row flex-container">
          <div className="field margin-top--xs margin-bottom--xs">
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Full name"
                  variant="outlined"
                  size="small"
                  value={value}
                  required
                  fullWidth
                  onChange={onChange}
                  error={!!error}
                  helperText={
                    error ? error.message : 'Your name is used to interact with you client.'
                  }
                />
              )}
              rules={{ required: 'Please fill this field' }}
            />
          </div>
        </div>
        <div className="form-row flex-container">
          <div className="field margin-top--xs margin-bottom--xs">
            <Controller
              name="tagLine"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Tag line"
                  variant="outlined"
                  size="small"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  required
                  error={!!error}
                  helperText={error ? error.message : 'Tip: Keep the tagline short and crisp.'}
                />
              )}
              rules={{ required: 'Please fill this field' }}
            />
          </div>
        </div>
        <div className="form-row flex-container">
          <div className="field margin-top--xs margin-bottom--xs">
            <Controller
              name="aboutMe"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="About me"
                  variant="outlined"
                  size="small"
                  value={value}
                  required
                  fullWidth
                  multiline
                  rows={14}
                  placeholder="Use this space to introduce yourself to the client. You could mention your professional achievements as well as your personal interests like hobbies etc."
                  onChange={onChange}
                  error={!!error}
                  helperText={
                    error ? (
                      error.message
                    ) : (
                      <>
                        {`Character count ${value?.length || 0}/1000. ${
                          1000 - value?.length || 0
                        } characters remaining.`}
                        <br />
                        Tip: Use simple language to help your client understand easily.
                      </>
                    )
                  }
                />
              )}
              rules={{
                required: 'Please fill this field',
                maxLength: {
                  value: 1000,
                  message: 'Number of characters exceeding 1000'
                }
              }}
            />
          </div>
          <div className="field margin-bottom--xs">
            <Controller
              name="featurePicUrl"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <UploadPic
                  helperText={[
                    'Adjust the image to ensure the aspect ratio is 16:9. This helps improve your website performance like load times.',
                    'Tip: Use a candid image of yourself. It helps in building a human connection with the client.'
                  ]}
                  aspectRatioWidth={16}
                  aspectRatioHeight={9}
                  name="Feature picture"
                  setCropData={setCroppedImage('featurePic')}
                  imageUrl={value}
                />
              )}
            />
          </div>
        </div>
        <SocialLinks control={control} />
        <div className="floating-button">
          <Fab style={{ width: '120px' }} variant="extended" color="primary" type="submit">
            Save
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .container {
          padding: 0px 10px;
        }
        .profile-pic-container {
          min-width: 80px;
        }
        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default Personal;
