/* eslint-disable no-undef */
import { FileCopyOutlined } from '@mui/icons-material';
import HttpsIcon from '@mui/icons-material/Https';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Fab, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import DomainIcon from '@mui/icons-material/Domain';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStoreState } from 'easy-peasy';
import Tooltip from '@mui/material/Tooltip';

import { debounce } from '../helper/util.helper';
import { checkForDomain, storeDomain } from './website/shared/website.service';
import { auth, database } from '../helper/firebase.helper';
import { domainStatusEnum } from '../helper/constants.helper';

const Domain = () => {
  const [domainData, setDomainData] = useState();
  const [domainAvailable, setDomainAvailable] = useState('');
  const { handleSubmit, control, reset, formState } = useForm();
  const userData = useStoreState((state) => state.dashboard.userData);
  const isPaid = !!(userData && userData.is_paid);
  const checkDomainAvailability = async (domain) => {
    setDomainAvailable(await checkForDomain(domain?.toLowerCase()));
  };

  const debouncedSearch = useCallback(debounce(checkDomainAvailability, 1000), []);

  const setSiteDomain = (e, update) => {
    update(e.target.value);
    if (!domainData?.siteDomain) {
      debouncedSearch(e.target.value);
    }
  };

  const copySiteDomain = (domain) => {
    navigator.clipboard.writeText(`https://${domain}.practiceid.site`);
    toast.success('copied');
  };

  const copyToClipboard = (copyText) => {
    navigator.clipboard.writeText(copyText);
    toast.success('copied');
  };

  const onSubmit = async (data) => {
    data.siteDomain = data.siteDomain.toLowerCase();
    const isDomainAvailable = await checkForDomain(data.siteDomain);
    if (!isDomainAvailable && !domainData?.siteDomain) {
      setDomainAvailable(false);
      return;
    }

    await toast.promise(storeDomain(data), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't to save`
    });
    getDomainData();
  };

  useEffect(() => {
    getDomainData();
  }, []);

  const getDomainData = () => {
    return database
      .doc(`practitioners/${auth.currentUser?.uid}/details/domain`)
      .onSnapshot((doc) => {
        setDomainData(doc.data());
      });
  };

  useEffect(() => {
    reset(domainData);
  }, [domainData, reset]);

  return (
    <div className="container">
      <Helmet>
        <title>Domain - PracticeID</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>PracticeID site domain</p>
        <hr />
        <p className="text--sm">
          Please choose your PracticeID site domain carefully. This can NOT be changed later.
        </p>
        <div className="field margin-top margin-bottom--xs">
          <Controller
            name="siteDomain"
            control={control}
            defaultValue=""
            rules={{ required: 'Please fill this field', pattern: /^[a-zA-Z0-9]*[a-zA-Z0-9]$/ }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="flex-container">
                <TextField
                  label="Site domain"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={value}
                  disabled={!!domainData?.siteDomain}
                  fullWidth
                  onChange={(e) => setSiteDomain(e, onChange)}
                  error={!!error || domainAvailable === false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p>.practiceid.site</p>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    error
                      ? error.message
                      : value && domainAvailable !== ''
                      ? domainAvailable
                        ? 'Domain Available'
                        : 'Domain not available'
                      : 'Tip: Choose a domain name which is easier for your clients to remember.'
                  }
                />
                {domainData?.siteDomain && (
                  <div className="copy-icon margin-top--xxs">
                    <FileCopyOutlined onClick={() => copySiteDomain(value)} />
                  </div>
                )}
              </div>
            )}
          />
        </div>
        <div className="flex-container margin-top--xl">
          <p>Custom domain</p>
          {!isPaid ? (
            <Tooltip title="Subscribe to professional plan to use custom domain">
              <WarningAmberIcon fontSize="small" sx={{ marginLeft: '10px' }} color="warning" />
            </Tooltip>
          ) : null}
        </div>
        <hr />
        <p className="text--sm">If you already own your domain, you can link it to Practice ID.</p>
        <div className="field margin-top margin-bottom--xs">
          <Controller
            name="customDomain"
            control={control}
            defaultValue=""
            rules={{
              pattern: /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error, isDirty } }) => (
              <div className="flex-container">
                <TextField
                  label="Domain Name"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={value}
                  fullWidth
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={!isPaid}
                  error={!!error}
                  placeholder="Domain name you would like to link"
                  helperText="You will need to change DNS records for this domain with your registrar to use it."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainIcon />
                      </InputAdornment>
                    ),
                    endAdornment:
                      isDirty || !value ? null : domainData?.status ===
                        domainStatusEnum.VERIFIED ? (
                        <InputAdornment position="end">
                          <HttpsIcon fontSize="small" style={{ color: 'green' }} />
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <WarningAmberIcon fontSize="small" color="secondary" />
                        </InputAdornment>
                      )
                  }}
                />
              </div>
            )}
          />
          {domainData?.customDomain ? (
            <>
              <p className="margin-top--md">
                Add a {domainData.customDomain?.match(/\./g)?.length === 1 ? 'A' : 'CNAME'} record
                to your domain&apos;s DNS records
              </p>
              <TableContainer sx={{ paddingBottom: '40px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Record type</TableCell>
                      <TableCell>Host</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {domainData.customDomain?.match(/\./g)?.length === 1 ? (
                        <>
                          <TableCell>A</TableCell>
                          <TableCell>
                            <div className="flex-container align-center">
                              {domainData?.customDomain}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex-container align-center">
                              {process.env.REACT_APP_WEBSITE_IP}
                              <FileCopyOutlined
                                fontSize="small"
                                sx={{ marginLeft: '5px' }}
                                onClick={() => copyToClipboard(process.env.REACT_APP_WEBSITE_IP)}
                              />
                            </div>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>CNAME</TableCell>
                          <TableCell>
                            <div className="flex-container align-center">
                              {domainData.customDomain?.split(/\./g).slice(0, -2).join('.')}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex-container align-center">
                              {domainData.siteDomain}.practiceid.site
                              <FileCopyOutlined
                                sx={{ marginLeft: '5px' }}
                                fontSize="small"
                                onClick={() =>
                                  copyToClipboard(`${domainData.siteDomain}.practiceid.site`)
                                }
                              />
                            </div>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </div>
        <div className="floating-button">
          <Fab
            style={{ width: '120px' }}
            variant="extended"
            color="primary"
            type="submit"
            disabled={!formState.isValid}>
            Confirm
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .container {
          padding: 10px;
        }
        .floating-button {
          position: fixed;
          bottom: 30px;
          right: 10%;
        }
        .field {
          max-width: 500px;
          position: relative;
        }
        .copy-icon {
          margin-left: 10px;
          cursor: pointer;
          height: fit-content;
        }
        @media only screen and (max-width: 600px) {
          .floating-button {
            bottom: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default Domain;
