import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const DayItem = ({
  day,
  startTime,
  endTime,
  isActive,
  onCheckboxChange,
  onTimeChange,
  timeSlotOption,
  timeSlotOption1
}) => {
  const getDayName = (day) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[day];
  };
  const dayName = getDayName(day);

  return (
    <div className="flex-container margin-bottom--md day-div">
      <div>
        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={() => onCheckboxChange(day)} />}
          label={dayName}
        />
      </div>
      <div className="timeslot-outer-div">
        {!isActive && <p style={{ marginTop: '12px' }}>Unavailable</p>}
        {isActive && (
          <div className="timeslot-inner-div">
            <span style={{ marginRight: '10px' }} className="top-margin">
              From
            </span>
            <span className="first-timeslot">
              <Select
                size="small"
                style={{
                  marginRight: '10px',
                  minWidth: '120px'
                }}
                value={startTime}
                onChange={(e) => onTimeChange(day, 'startTime', e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200
                    }
                  }
                }}>
                {timeSlotOption.map((timeSlot, index) => (
                  <MenuItem key={index} value={timeSlot}>
                    {timeSlot}
                  </MenuItem>
                ))}
              </Select>
            </span>
            <span style={{ marginRight: '10px' }} className="top-margin">
              to
            </span>
            <span>
              <Select
                size="small"
                value={endTime}
                style={{ minWidth: '120px' }}
                onChange={(e) => onTimeChange(day, 'endTime', e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200
                    }
                  }
                }}>
                {timeSlotOption1.map((timeSlot, index) => (
                  <MenuItem key={index} value={timeSlot}>
                    {timeSlot}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </div>
        )}
      </div>

      <style jsx>{`
        .day-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 0px 15px 0px;
          justify-content: space-between;
        }
        .timeslot-outer-div {
          margin-right: 30%;
        }
        // .first-timeslot {
        //   // margin-right: 10px;
        // }
        .top-margin {
          margin-top: 12px;
        }
        @media only screen and (max-width: 765px) {
          .timeslot-outer-div {
            margin-right: 0%;
          }
        }
        @media only screen and (max-width: 500px) {
          .day-div {
            min-height: 85px;
            // text-align: center;
            flex-direction: column;
            justify-content: center;
            align-item: center;
            align-content: center;
          }
        }
        @media only screen and (max-width: 360px) {
          .timeslot-inner-div {
            display: flex;
            // flex-direction: column;
          }
          // .first-timeslot {
          //   margin-right: 0px;
          // }
        }
      `}</style>
    </div>
  );
};

export default DayItem;
