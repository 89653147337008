import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DayItem from './DayItem';
import {
  storeAvailabilityToDb,
  getAvailabilityFromDb,
  timeSlotOption,
  timeSlotOption1
} from '../shared/meeting.service';
import toast from 'react-hot-toast';
import Fab from '@mui/material/Fab';

const Availability = () => {
  const initialAvailability = [
    { day: 0, startTime: '', endTime: '', isActive: false },
    { day: 1, startTime: '', endTime: '', isActive: false },
    { day: 2, startTime: '', endTime: '', isActive: false },
    { day: 3, startTime: '', endTime: '', isActive: false },
    { day: 4, startTime: '', endTime: '', isActive: false },
    { day: 5, startTime: '', endTime: '', isActive: false },
    { day: 6, startTime: '', endTime: '', isActive: false }
  ];

  const [availability, setAvailability] = useState(initialAvailability);
  const [availabilityChanged, setAvailabilityChanged] = useState(false);

  useEffect(() => {
    const fetchAvailability = async () => {
      const data = await getAvailabilityFromDb();
      if (data) {
        setAvailability(data);
      }
    };
    fetchAvailability();
  }, []);

  const handleSave = async () => {
    const practitionerTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const invalidDays = availability.filter(
      (item) => item.isActive && (!item.startTime || !item.endTime)
    );
    if (invalidDays.length > 0) {
      toast.error('Please select both start and end times for all active days');
      return;
    }

    await toast.promise(storeAvailabilityToDb(availability, practitionerTimezone), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't save`
    });
    setAvailabilityChanged(false);
  };

  const handleCheckboxChange = (day) => {
    setAvailability((prev) => {
      const updatedAvailability = prev.map((item) => {
        if (item.day === day) {
          return { ...item, isActive: !item.isActive };
        }
        return item;
      });
      setAvailabilityChanged(true);
      return updatedAvailability;
    });
  };

  const handleTimeChange = (day, type, value) => {
    setAvailability((prev) => {
      if (type === 'startTime') {
        const endIdx = prev.findIndex((item) => item.day === day);
        const endTime = prev[endIdx].endTime;
        if (endTime !== '' && timeSlotOption.indexOf(value) > timeSlotOption1.indexOf(endTime)) {
          toast.error('Start time cannot be later than end time');
          return prev;
        }
        if (endTime !== '' && value === endTime && value !== '12:00 AM') {
          toast.error('Start time and end time cannot be the same');
          return prev;
        }
      } else if (type === 'endTime') {
        const startIdx = prev.findIndex((item) => item.day === day);
        const startTime = prev[startIdx].startTime;
        if (
          startTime !== '' &&
          timeSlotOption1.indexOf(value) < timeSlotOption.indexOf(startTime)
        ) {
          toast.error('End time cannot be earlier than start time');
          return prev;
        }
        if (startTime !== '' && value === startTime && value !== '12:00 AM') {
          toast.error('End time and start time cannot be the same');
          return prev;
        }
      }

      const updatedAvailability = prev.map((item) => {
        if (item.day === day) {
          return { ...item, [type]: value };
        }
        return item;
      });
      setAvailabilityChanged(true);
      return updatedAvailability;
    });
  };

  return (
    <div>
      <div>
        <h4 className="heading-div">Set Availability</h4>
      </div>
      <div className="content-div margin-top--sm">
        {availability.map(({ day, startTime, endTime, isActive }) => (
          <DayItem
            key={day}
            day={day}
            startTime={startTime}
            endTime={endTime}
            isActive={isActive}
            onCheckboxChange={handleCheckboxChange}
            onTimeChange={handleTimeChange}
            timeSlotOption={timeSlotOption}
            timeSlotOption1={timeSlotOption1}
          />
        ))}

        <div className="floating-button">
          <Fab
            onClick={handleSave}
            disabled={!availabilityChanged}
            style={{ width: '120px' }}
            variant="extended"
            color="primary">
            Save
          </Fab>
        </div>
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 5px 15px 0px;
        }
        .content-div {
          padding: 5px 5px 15px 0px;
        }
        .day-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 0px 15px 0px;
          justify-content: space-between;
        }
        .timeslot-outer-div {
          margin-right: 30%;
        }
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
        @media only screen and (max-width: 765px) {
          .timeslot-outer-div {
            margin-right: 0%;
          }
        }
        @media only screen and (max-width: 500px) {
          .day-div {
            min-height: 85px;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-item: center;
            align-content: center;
          }
        }
        @media only screen and (max-width: 300px) {
          .timeslot-inner-div {
            display: flex;
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default Availability;
