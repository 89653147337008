import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

const Popup = ({ children, isOpen, toggleOpen }) => {
  return window.innerWidth < 960 ? (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={toggleOpen(false)}
      onOpen={toggleOpen(true)}
      PaperProps={{
        sx: {
          top: '20%',
          borderRadius: '10px'
        }
      }}>
      {children}
    </SwipeableDrawer>
  ) : (
    <Dialog
      PaperProps={{ sx: { borderRadius: '20px' } }}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={toggleOpen(false)}>
      <DialogContent sx={{}}>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
