import React from 'react';
import { IconButton } from '@mui/material';
//import {MenuItem} from '@mui/material/';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Practiceid from '../../../assets/practiceid.svg';
import styles from './logocontainer.module.scss';
import { Menu } from '@mui/icons-material';
import { useMatch } from 'react-router-dom';

export default function Logo({ handleSidebar }) {
  const dashboard = useMatch('/dashboard/*');

  return (
    <div className={styles.logoContainer}>
      {dashboard ? (
        <div className={styles.actionButton}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleSidebar}>
            <Menu />
          </IconButton>
        </div>
      ) : null}
      <div className={styles.logo}>
        <div className={styles.pracsite}>
          <img src={Practiceid} alt="PracticeID" />
        </div>
      </div>
    </div>
  );
}
