import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { auth } from '../../../helper/firebase.helper';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const timer = setInterval(() => {
      console.log(auth.currentUser?.emailVerified);
      if (
        auth.currentUser?.emailVerified &&
        searchParams.get('email') === auth.currentUser?.email
      ) {
        const password = sessionStorage.getItem(auth.currentUser.email);
        auth.signInWithEmailAndPassword(auth.currentUser.email, password).then(() => {
          navigate('/dashboard/overview');
          sessionStorage.removeItem(auth.currentUser.email);
        });
      } else {
        auth.currentUser?.reload();
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="verify">
      <h4>You&apos;re almost there</h4>
      <p>
        Thanks for signing up! You would see a verification link in your email (it might in spam
        folder). Verify you email to start customising your website
      </p>
      <style jsx>{`
        .verify {
          text-align: center;
          padding: 5% 10% 10% 10%;
        }
      `}</style>
    </div>
  );
};

export default VerifyEmail;
