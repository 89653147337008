import React from 'react';
import Button from '@mui/material/Button';
import { getDate, getTime, getTimeSlot } from './inbox.service.js';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EventIcon from '@mui/icons-material/Event';

const Contact = ({ contact, popupOpen }) => {
  return (
    <div className="flex-container text--sm  outer-container margin-top--md ">
      <div className="icon-div">
        <div className="icon flex-container justify-center align-center">
          {contact.email.charAt(0).toUpperCase()}
        </div>
      </div>
      <div className="email-div inner-div">
        <p className="semi-bold">{contact.email}</p>
        {contact.source == 'stay_in_touch' ? null : (
          <div>
            <p className="semi-bold">{contact.name}</p>
            <p className="semi-bold">{contact.mobile}</p>
          </div>
        )}
      </div>
      <div className="source-div inner-div">
        <p className="semi-bold">{getTime(contact.creation_time)}</p>
        <p className="semi-bold">
          {contact.source == 'contact_me'
            ? 'Contact me'
            : contact.source == 'stay_in_touch'
            ? 'Stay in touch'
            : contact.source == 'meeting'
            ? 'Request meeting'
            : null}
        </p>
      </div>
      <div className="preview-div inner-div">
        {contact.source === 'meeting' ? (
          <div className="flex-container">
            <div>
              <EventIcon color="primary" fontSize="small" />
              {/* &nbsp; */}
            </div>
            <p className=" semi-bold" style={{ marginLeft: '5px' }}>
              {contact.meeting_title} - {getDate(contact.slot_start)} from
              {getTimeSlot(contact.slot_start)} to {getTimeSlot(contact.slot_end)}
            </p>
          </div>
        ) : null}
        <div className="flex-container">
          <div>
            {contact.source === 'stay_in_touch' ? null : (
              <EmailOutlinedIcon color="primary" fontSize="small" />
            )}
          </div>
          <p className="semi-bold" style={{ marginLeft: '5px' }}>
            {/* &nbsp; */}
            {contact.message
              ? contact.message.length > 165
                ? contact.message.slice(0, 165) + '...'
                : contact.message
              : null}
          </p>
        </div>
      </div>
      <div
        className={
          (contact.source == 'stay_in_touch' ? 'btn' : null) +
          ' ' +
          'actions-div' +
          ' ' +
          'inner-div'
        }>
        <Button
          style={{ maxHeight: '25px' }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => popupOpen(contact)}>
          View details
        </Button>
      </div>
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .icon-div {
            width: 4%;
          }
          .email-div {
            width: 17%;
            overflow: hidden;
          }
          .source-div {
            width: 17%;
          }
          .preview-div {
            width: 37%;
          }
          .actions-div {
            width: 20%;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
          .btn{
            visibility:hidden;
          }
          .semi-bold {
            font-weight: 500;
          }
          
          @media only screen and ( max-width : 900px ) {
            .email-div {
              width: 40% !important;
            }
            .source-div {
              display: none ;
            }
            .preview-div {
              display: none;
            }
            .actions-div {
              width: 42% !important;
            }
          }
          
          @media only screen and (max-width: 350px) {
            .icon-div {
              display: none;
            }
            .actions-div {
              width: 54% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Contact;
